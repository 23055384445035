/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
   ========================================================================== */
/**
 * 1. Change the default font family in all browsers (opinionated).
 * 2. Correct the line height in all browsers.
 * 3. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  font-family: sans-serif; /* 1 */
  line-height: 1.15; /* 2 */
  -ms-text-size-adjust: 100%; /* 3 */
  -webkit-text-size-adjust: 100%; /* 3 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
figcaption,
figure {
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button; /* 2 */
}

button,
[type=button],
[type=reset],
[type=submit] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
  /**
   * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
   */
}
[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  color: inherit; /* 2 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE 9-.
 */
menu {
  display: block;
}

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide, .swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

/* Auto Height */
.swiper-container-autoheight {
  height: auto;
}
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height;
}

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

/* Arrows */
.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}

.swiper-button-prev.swiper-button-black, .swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-white, .swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}

.swiper-button-next.swiper-button-black, .swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white, .swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

/* Common Styles */
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}

/* Bullets */
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0);
}
.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
}

/* Progress */
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}
.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
}

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5);
}
.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff;
}
.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000;
}

/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
}
.swiper-container-3d .swiper-wrapper, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-coverflow .swiper-wrapper, .swiper-container-flip .swiper-wrapper {
  -ms-perspective: 1200px;
}

.swiper-container-cube, .swiper-container-flip {
  overflow: visible;
}

.swiper-container-cube .swiper-slide, .swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-cube .swiper-slide .swiper-slide, .swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube .swiper-slide-active, .swiper-container-flip .swiper-slide-active, .swiper-container-cube .swiper-slide-active .swiper-slide-active, .swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-shadow-top, .swiper-container-flip .swiper-slide-shadow-top, .swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right, .swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* Cube */
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-prev, .swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}
.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-fade .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
}
.swiper-zoom-container img, .swiper-zoom-container svg, .swiper-zoom-container canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}
.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}
[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background-color: #BACAD5;
  border-radius: 50px;
  left: 0;
  right: 0px;
  opacity: 1;
  height: 100%;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 1;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 3px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}
.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #CCC;
}
.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader, .mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  display: none;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}
.mfp-arrow:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}
.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}
.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}
.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@font-face {
  font-family: "Gilroy-Bold";
  src: url("../fonts/Gilroy-Bold/Gilroy-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Gilroy-Bold-Italic";
  src: url("../fonts/Gilroy-Bold-Italic/Gilroy-Bold-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Gilroy-Medium";
  src: url("../fonts/Gilroy-Medium/Gilroy-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Gilroy-Medium-Italic";
  src: url("../fonts/Gilroy-Medium-Italic/Gilroy-Medium-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("../fonts/Gilroy-SemiBold/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Gilroy-SemiBold-Italic";
  src: url("../fonts/Gilroy-SemiBold-Italic/Gilroy-SemiBold-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

#SVG_container {
  position: absolute;
  height: 0;
  width: 0;
}

#SVG_container svg {
  position: absolute;
  height: 0;
  width: 0;
}

.icon-1 {
  font-size: 5rem;
  width: 1.08em;
}

.icon-10 {
  font-size: 4rem;
  width: 1.075em;
}

.icon-11 {
  font-size: 4rem;
  width: 1.1em;
}

.icon-12 {
  font-size: 4rem;
  width: 1.05em;
}

.icon-13 {
  font-size: 4rem;
  width: 1.1em;
}

.icon-2 {
  font-size: 5rem;
  width: 1.02em;
}

.icon-3 {
  font-size: 5rem;
  width: 1.1em;
}

.icon-4 {
  font-size: 5rem;
  width: 1.1em;
}

.icon-43 {
  font-size: 2.4rem;
  width: 1em;
}

.icon-44 {
  font-size: 2.4rem;
  width: 1.0416666667em;
}

.icon-49 {
  font-size: 2.4rem;
  width: 1em;
}

.icon-5 {
  font-size: 5rem;
  width: 1.02em;
}

.icon-50 {
  font-size: 2.4rem;
  width: 1em;
}

.icon-51 {
  font-size: 1.6rem;
  width: 1em;
}

.icon-52 {
  font-size: 1.4rem;
  width: 1em;
}

.icon-57 {
  font-size: 2.4rem;
  width: 1em;
}

.icon-6 {
  font-size: 5rem;
  width: 1.06em;
}

.icon-69 {
  font-size: 2.4rem;
  width: 1em;
}

.icon-7 {
  font-size: 4rem;
  width: 1.1em;
}

.icon-75 {
  font-size: 0.3rem;
  width: 3em;
}

.icon-8 {
  font-size: 4rem;
  width: 1.1em;
}

.icon-9 {
  font-size: 4rem;
  width: 1.1em;
}

.icon-add {
  font-size: 2.4rem;
  width: 1em;
}

.icon-arrow-bottom {
  font-size: 1rem;
  width: 1em;
}

.icon-arrow-right {
  font-size: 1.3rem;
  width: 1.2307692308em;
}

.icon-arrows {
  font-size: 2.4rem;
  width: 1em;
}

.icon-back {
  font-size: 2.4rem;
  width: 1em;
}

.icon-basket {
  font-size: 2.3rem;
  width: 1em;
}

.icon-burger {
  font-size: 1.8rem;
  width: 1.3333333333em;
}

.icon-cart {
  font-size: 2rem;
  width: 1.05em;
}

.icon-check {
  font-size: 0.7rem;
  width: 1.2857142857em;
}

.icon-city-location {
  font-size: 1.8rem;
  width: 0.6666666667em;
}

.icon-clock {
  font-size: 1.6rem;
  width: 1em;
}

.icon-close {
  font-size: 1.2rem;
  width: 1em;
}

.icon-cubes {
  font-size: 2.4rem;
  width: 1em;
}

.icon-delete {
  font-size: 2.4rem;
  width: 1em;
}

.icon-doc {
  font-size: 1.6rem;
  width: 0.75em;
}

.icon-down {
  font-size: 0.9rem;
  width: 1.5555555556em;
}

.icon-down-1 {
  font-size: 0.5rem;
  width: 2em;
}

.icon-down-2 {
  font-size: 0.7rem;
  width: 1.7142857143em;
}

.icon-email {
  font-size: 1.6rem;
  width: 1em;
}

.icon-en-flag {
  font-size: 2.4rem;
  width: 1em;
}

.icon-eye {
  font-size: 2.4rem;
  width: 1em;
}

.icon-facebook {
  font-size: 3.2rem;
  width: 1em;
}

.icon-favorite {
  font-size: 2rem;
  width: 1em;
}

.icon-fb {
  font-size: 3rem;
  width: 1em;
}

.icon-file {
  font-size: 1.6rem;
  width: 0.75em;
}

.icon-fill-like {
  font-size: 1.2rem;
  width: 1.25em;
}

.icon-google {
  font-size: 3rem;
  width: 1em;
}

.icon-home {
  font-size: 2.4rem;
  width: 1em;
}

.icon-info {
  font-size: 1.6rem;
  width: 1em;
}

.icon-instagram {
  font-size: 3.2rem;
  width: 1em;
}

.icon-left {
  font-size: 1.4rem;
  width: 0.6428571429em;
}

.icon-like {
  font-size: 1.2rem;
  width: 1.1666666667em;
}

.icon-lk {
  font-size: 2.4rem;
  width: 1em;
}

.icon-location {
  font-size: 1.8rem;
  width: 0.6666666667em;
}

.icon-location-1 {
  font-size: 1.6rem;
  width: 1em;
}

.icon-login {
  font-size: 2.4rem;
  width: 1em;
}

.icon-maestro {
  font-size: 3rem;
  width: 1.3em;
}

.icon-mail {
  font-size: 1.2rem;
  width: 1.8333333333em;
}

.icon-master-card {
  font-size: 3rem;
  width: 1.3333333333em;
}

.icon-mir {
  font-size: 1.5rem;
  width: 3.4666666667em;
}

.icon-mirror {
  font-size: 2rem;
  width: 1em;
}

.icon-phone {
  font-size: 1.6rem;
  width: 1em;
}

.icon-play {
  font-size: 3.5rem;
  width: 1.0285714286em;
}

.icon-plus {
  font-size: 1rem;
  width: 1em;
}

.icon-profile {
  font-size: 2rem;
  width: 1em;
}

.icon-rating {
  font-size: 2.4rem;
  width: 6.6666666667em;
}

.icon-restore {
  font-size: 1.6rem;
  width: 1.0625em;
}

.icon-right {
  font-size: 1.4rem;
  width: 0.6428571429em;
}

.icon-ru-flag {
  font-size: 2.4rem;
  width: 1em;
}

.icon-sale {
  font-size: 2rem;
  width: 1em;
}

.icon-save {
  font-size: 2.4rem;
  width: 1em;
}

.icon-search {
  font-size: 1.6rem;
  width: 1em;
}

.icon-star {
  font-size: 2.4rem;
  width: 1em;
}

.icon-top {
  font-size: 0.9rem;
  width: 1.5555555556em;
}

.icon-top-2 {
  font-size: 0.7rem;
  width: 1.7142857143em;
}

.icon-unload {
  font-size: 2.4rem;
  width: 1em;
}

.icon-visa {
  font-size: 1.5rem;
  width: 3.2em;
}

.icon-vk {
  font-size: 3rem;
  width: 1em;
}

.icon-vkontakte {
  font-size: 3.2rem;
  width: 1em;
}

.icon-webest {
  font-size: 1.6rem;
  width: 5em;
}

.icon-write {
  font-size: 2.4rem;
  width: 1em;
}

body {
  font-family: "Gilroy-Medium";
  line-height: 1.3;
  font-size: 14px;
  color: #000000;
  overflow-x: hidden;
}
body * {
  box-sizing: border-box;
  font-family: "Gilroy-Medium";
}

a {
  color: #000000;
  text-decoration: none;
  transition: color ease 0.3s;
}
a:hover {
  color: #56AF40;
}

p {
  margin: 0 0 20px;
}
p:last-child {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.body--fix {
  position: fixed;
  left: 0;
  right: 0;
}

.section-header {
  margin-bottom: 30px;
}
.section-header__title {
  font-family: "Gilroy-Bold";
  font-size: 24px;
  line-height: 24px;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.page {
  flex: 1;
  margin-bottom: 45px;
}

.container {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 1320px) {
  .container {
    padding: 0 30px;
  }
}
@media only screen and (max-width: 768px) {
  .container {
    padding: 0 16px;
  }
}

.btn {
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  box-shadow: none;
  font-family: "Gilroy-SemiBold";
  padding: 9px 19px;
  font-size: 14px;
  line-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all ease 0.3s;
}
.btn__img {
  margin-left: 10px;
}
.btn:disabled {
  opacity: 0.5;
}
.btn--base:hover:not(:disabled), .btn--base:active, .btn--big:hover:not(:disabled), .btn--big:active, .btn--counter:hover:not(:disabled), .btn--counter:active {
  background-color: #71C95C;
  color: #FFFFFF;
}
.btn--base {
  color: #FFFFFF;
  border-radius: 5px;
  background-color: #56AF40;
}
.btn--big {
  color: #FFFFFF;
  font-family: "Gilroy-Bold";
  background-color: #56AF40;
  padding: 13px 20px;
  border-radius: 10px;
}
.btn--light {
  background-color: #E7F1E5;
  color: #000000;
  font-family: "Gilroy-Bold";
  padding: 13px 20px;
  border-radius: 10px;
}
.btn--light .icon {
  font-size: 20px;
  color: #BBDFB3;
}
.btn--light:hover:not(:disabled), .btn--light:active {
  color: #56AF40;
}
.btn--counter {
  background-color: #56AF40;
  width: max-content;
  color: #FFFFFF;
  border-radius: 5px;
  font-size: 21px;
  padding: 6px 11px;
}
.btn--small {
  color: #FFFFFF;
  width: max-content;
  font-size: 20px;
  background-color: #BACAD5;
  padding: 2px 7px;
  border-radius: 7px;
}
.btn--small:hover:not(:disabled), .btn--small:active {
  background-color: #ABB9C2;
}
.btn--icon {
  color: #FFFFFF;
  padding: 8px;
  border-radius: 10px;
}
.btn--icon .icon {
  width: 24px;
  height: 24px;
}
.btn--icon:hover:not(:disabled), .btn--icon:active {
  background-color: #56AF40;
}
.btn--upward {
  color: #FFFFFF;
  width: max-content;
  padding: 17px 14px;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 4px 14px rgba(53, 151, 63, 0.35);
}
.btn--upward .icon {
  font-size: 7px;
  color: #000000;
  transition: color ease 0.3s;
}
.btn--upward:hover:not(:disabled), .btn--upward:active {
  background-color: #56AF40;
}
.btn--upward:hover:not(:disabled) .icon, .btn--upward:active .icon {
  color: #FFFFFF;
}
.btn--red {
  color: #FFFFFF;
  border-radius: 5px;
  padding: 15px 10px;
  background-color: #EB5757;
}
.btn--red:hover:not(:disabled), .btn--red:active {
  opacity: 0.7;
}
.btn--transparent {
  padding: 4px;
  color: #56AF40;
}
.btn--transparent:hover:not(:disabled), .btn--transparent:active {
  text-decoration: underline;
}
.btn--input {
  position: absolute;
  top: 0;
  right: 0;
  width: max-content;
  padding: 8px;
}
.btn--input .icon {
  font-size: 24px;
  color: #200E32;
  transition: color ease 0.3s;
}
.btn--input.active .icon {
  color: #56AF40;
}

.counter {
  display: flex;
}
.counter__value {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gilroy-Bold";
  padding: 8px 0;
  width: 100%;
  background: #F4F4F4;
  border-radius: 5px;
  margin: 0 8px;
}
.counter--small .counter__value {
  padding: 4px 0;
  background: transparent;
  margin: 0 12px;
}

.link {
  font-family: "Gilroy-Bold";
  color: #56AF40;
  text-decoration: underline;
  font-size: 14px;
  line-height: 130%;
  cursor: pointer;
  transition: text-decoration ease 0.3s;
}
.link:hover {
  text-decoration: none;
}
.link--black {
  font-family: "Gilroy-Medium";
  color: #000000;
  line-height: 100%;
}
.link--black:hover {
  color: #000000;
}
.link--small {
  font-family: "Gilroy-Medium";
  font-size: 12px;
  line-height: 100%;
}

.info-title {
  display: flex;
  align-items: center;
  font-family: "Gilroy-SemiBold";
  font-size: 16px;
  line-height: 18px;
}

.info {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-left: 10px;
}
.info .icon {
  font-size: 16px;
  color: #5A6872;
}
.info:hover .tooltip {
  opacity: 1;
  pointer-events: all;
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}
.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F2F2F2;
  transition: 0.4s;
  border-radius: 50px;
}
.switch__slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 50%;
  transform: translateY(50%);
  background-color: #E0E0E0;
  transition: 0.4s;
  border-radius: 50%;
}
.switch input {
  display: none;
}
.switch input:checked + .switch__slider {
  background-color: #56AF40;
}
.switch input:checked + .switch__slider:before {
  transform: translate(16px, 50%);
  background-color: #FFFFFF;
}

.like-btn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #FFFFFF;
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 2;
  padding: 0;
  transition: transform ease 0.1s;
}
.like-btn__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 10px;
  transition: opacity ease 0.1s, color ease 0.3s;
}
.like-btn__icon:hover {
  color: #EB5757;
}
.like-btn__icon--filled {
  opacity: 0;
}
.like-btn--active .like-btn {
  transform: scale(0.95);
}
.like-btn--active .like-btn__icon--outline {
  opacity: 0;
}
.like-btn--active .like-btn__icon--filled {
  opacity: 1;
  color: #EB5757;
}

.back-btn {
  display: inline-flex;
  align-items: center;
  margin: 20px 0;
}
.back-btn__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #BACAD5;
  border-radius: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  margin-right: 12px;
}
.back-btn .icon {
  color: #FFFFFF;
  font-size: 10px;
}

.button-save {
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  background: #F4F4F4;
  border-radius: 10px;
  max-width: 320px;
  text-align: start;
  margin-bottom: 12px;
}
.button-save:last-child {
  margin-bottom: 0;
}
.button-save__title {
  line-height: 17px;
}
.button-save__icon {
  display: flex;
  padding: 4px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-right: 12px;
  transition: all ease 0.3s;
}
.button-save__icon:last-child {
  margin-right: 0;
}
.button-save__icon .icon {
  font-size: 24px;
  color: #56AF40;
  transition: all ease 0.3s;
}
.button-save:hover .button-save__icon {
  background: #56AF40;
}
.button-save:hover .icon {
  color: #FFFFFF;
}
.button-save--green {
  background: #E7F1E5;
}

.filter-btn {
  padding: 7px 13px;
  font-family: "Gilroy-Bold";
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #FFFFFF;
  border-radius: 10px;
  background: #000;
  position: relative;
  height: max-content;
}
.filter-btn__notification {
  position: absolute;
  top: -4px;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #56AF40;
}

.input-group__label {
  margin-bottom: 8px;
}
.input-group__input {
  position: relative;
}
.input-group__error {
  color: #FC6464;
  font-size: 12px;
}
.input-group--error .input {
  border: 1px solid #E53935;
}
.input-group--error .input-group__error {
  font-size: 11px;
  line-height: 130%;
  padding-top: 4px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 32px #F4F4F4 inset !important;
  color: #000000;
}

.input {
  background: #F4F4F4;
  color: #000000;
  padding: 12px 15px;
  min-height: 40px;
  border: 1px solid transparent;
  border-radius: 10px;
  box-shadow: none;
  outline: none;
  font-size: 12px;
  line-height: 130%;
  width: 100%;
  transition: background ease 0.3s, border ease 0.3s;
}
.input::-webkit-input-placeholder, .input:-ms-input-placeholder, .input::-moz-placeholder, .input:-moz-placeholder {
  color: #8B928C;
}
.input:hover:not(:disabled) {
  border: 1px solid #56AF40;
  color: #000000;
}
.input:hover:not(:disabled)::-webkit-input-placeholder, .input:hover:not(:disabled):-ms-input-placeholder, .input:hover:not(:disabled)::-moz-placeholder, .input:hover:not(:disabled):-moz-placeholder {
  color: #8B928C;
}
.input:focus:not(:disabled) {
  border: 1px solid #56AF40;
  color: #000000;
}
.input:focus:not(:disabled)::-webkit-input-placeholder, .input:focus:not(:disabled):-ms-input-placeholder, .input:focus:not(:disabled)::-moz-placeholder, .input:focus:not(:disabled):-moz-placeholder {
  color: #8B928C;
}
.input:disabled {
  cursor: not-allowed;
  background: #FFFFFF !important;
  opacity: 0.5;
  border: 1px solid transparent;
}
.input--white {
  background: #FFFFFF;
  line-height: 18px;
}
.input--white:hover:not(:disabled), .input--white:focus:not(:disabled) {
  border: 1px solid #EB5757;
}

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.checkbox [type=checkbox] {
  display: none;
}
.checkbox [type=checkbox]:checked ~ .checkbox__indicator {
  background: #56AF40;
}
.checkbox [type=checkbox]:checked ~ .checkbox__indicator .icon {
  transform: scale(1);
}
.checkbox [type=checkbox]:disabled ~ .checkbox__indicator {
  cursor: not-allowed;
  background: #E0E0E0 !important;
}
.checkbox [type=checkbox]:disabled ~ .checkbox__description {
  color: #000000;
  cursor: not-allowed;
}
.checkbox__indicator {
  background: #E7F1E5;
  height: 20px;
  width: 20px;
  min-width: 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  transition: background ease 0.3s, border ease 0.3s;
}
.checkbox__indicator .icon {
  color: #ffffff !important;
  font-size: 7px;
}
.checkbox__description {
  font-size: 12px;
  line-height: 100%;
  color: #000000;
  padding-left: 14px;
  flex: 1;
  user-select: none;
}
.checkbox__description a {
  text-decoration: underline;
}
.checkbox--red .checkbox__description {
  font-family: "Gilroy-SemiBold";
  color: #EB5757;
}

.radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.radio [type=radio] {
  display: none;
}
.radio [type=radio]:checked ~ .radio__indicator:after {
  background: #56AF40;
  top: 3px;
  bottom: 3px;
  left: 3px;
  right: 3px;
}
.radio [type=radio]:checked ~ .radio__description {
  color: #333333;
}
.radio [type=radio]:disabled ~ .radio__indicator {
  cursor: not-allowed;
  border: 2px solid rgba(0, 0, 0, 0.1) !important;
  background: rgba(0, 0, 0, 0.1);
}
.radio [type=radio]:disabled ~ .radio__indicator:after {
  background: #F6F7F9 !important;
}
.radio [type=radio]:disabled ~ .radio__description {
  color: rgba(0, 0, 0, 0.6);
  cursor: not-allowed;
}
.radio__indicator {
  background: #FFFFFF;
  display: flex;
  height: 19px;
  width: 19px;
  min-width: 19px;
  border: 2px solid #56AF40;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: block;
}
.radio__indicator:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  background: #FFFFFF;
  display: block;
  transition: top ease 0.3s, bottom ease 0.3s, left ease 0.3s, right ease 0.3s;
}
.radio__description {
  font-size: 14px;
  color: #828282;
  padding: 0 14px;
  flex: 1;
  user-select: none;
}

.textarea {
  min-height: 60px;
  min-width: 100%;
  max-width: 100%;
  width: 100% !important;
}

.form-default .btn {
  width: max-content;
}
.form-default__title {
  font-size: 12px;
  line-height: 100%;
  width: max-content;
}
.form-default__row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}
.form-default .input-group {
  margin-bottom: 27px;
}
.form-default__send {
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-default__send button {
  margin-right: 50px;
}
.form-default__send button span {
  white-space: nowrap;
}
.form-default__consent {
  display: flex;
  align-items: center;
}
.form-default__consent a {
  color: #000000;
}
.form-default__consent a:hover {
  text-decoration: none;
}
.form-default__label {
  margin-bottom: 8px;
}
.form-default__container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}
.form-default__wrapper {
  margin-bottom: 24px;
}
.form-default__wrapper--border {
  width: 100%;
  padding-top: 12px;
  border-top: 1px solid #EAEFF2;
}
.form-default__name {
  font-size: 16px;
  line-height: 20px;
}
.form-default__center {
  margin-bottom: 25px;
}
.form-default__center .form-default__title {
  margin: auto;
}
.form-default__upload {
  display: grid;
  grid-template-columns: repeat(5, 40px);
  grid-gap: 10px;
  margin-top: 16px;
}

.form-seminar {
  width: 100%;
  max-width: 260px;
}
.form-seminar__title {
  margin-bottom: 8px;
}
.form-seminar .input-group {
  margin-bottom: 8px;
}
.form-seminar .input-group .input {
  background: #FFFFFF;
}

.form-lk {
  margin-bottom: 30px;
}
.form-lk .input-group {
  margin-bottom: 16px;
}
.form-lk__consent {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}
.form-lk__consent a {
  color: #000000;
}
.form-lk__consent a:hover {
  text-decoration: none;
}

.seo-title {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

.title {
  font-family: "Gilroy-SemiBold";
  font-size: 20px;
  line-height: 24px;
}

.subtitle {
  font-family: "Gilroy-SemiBold";
  font-size: 16px;
  line-height: 20px;
}

.strong {
  font-family: "Gilroy-SemiBold";
}

.text-green {
  color: #56AF40;
}

.text-gray {
  color: #BACAD5;
}

.text-gray-primary {
  color: #8B928C;
}

.text-gray-second {
  color: #828282;
}

.typography h2 {
  font-family: "Gilroy-SemiBold";
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 16px;
}
.typography h3 {
  font-family: "Gilroy-SemiBold";
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 16px;
}
.typography p:not(:last-child) {
  margin-bottom: 18px;
}
.typography ul {
  margin-bottom: 18px;
  padding-left: 20px;
  list-style-type: disc;
}
.typography ul li {
  margin-bottom: 16px;
}
.typography img {
  height: 100%;
  width: 100%;
  max-height: 280px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
}

.animated {
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
}
.header {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.header-top-wrapper {
  background: #F4F4F4;
}
@media only screen and (max-width: 1100px) {
  .header-top-wrapper {
    display: none;
  }
}

.header-top {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
}
.header-top__info {
  display: flex;
  font-size: 14px;
  line-height: 130%;
}
.header-top__info-item {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 30px;
  text-decoration: none;
  color: #000000;
  transition: color ease 0.3s;
  font-size: 14px;
  line-height: 130%;
  cursor: pointer;
}
.header-top__info-item .icon {
  color: #56AF40;
}
.header-top__info-item .icon-location {
  font-size: 18px;
  margin-right: 6px;
}
.header-top__info-item .icon-down-1 {
  font-size: 5px;
  color: #BBDFB3;
  margin-left: 4px;
}
.header-top__info-item .icon-arrow-bottom {
  margin-left: 4px;
  font-size: 10px;
}
.header-top__info-item:last-child {
  margin-right: 0;
}
.header-top__info-item:hover {
  color: #56AF40;
}
.header-top__info-item:hover .tooltip {
  opacity: 1;
  pointer-events: all;
}
@media only screen and (max-width: 1220px) {
  .header-top__info-item {
    margin-right: 20px;
  }
}

.header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #DDE5EA;
  position: relative;
}
.header-main__logo {
  height: 40px;
  margin-right: 20px;
}
.header-main__logo img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.header-main__burger {
  display: none;
  position: relative;
}
.header-main__btn-mobile {
  display: none;
  padding: 0;
  width: 24px;
  height: 24px;
}
.header-main__btn-mobile .icon {
  color: #000000;
  font-size: 24px;
}
@media only screen and (min-width: 1100px) {
  .header-main__mobile.nav {
    display: none !important;
  }
  .header-main__logo {
    display: inline !important;
  }
  .header-main__search {
    width: 100%;
  }
}
@media only screen and (max-width: 1100px) {
  .header-main {
    padding: 12px 0;
    border-bottom: 1px solid transparent;
  }
  .header-main__mobile.nav {
    display: none;
  }
  .header-main__logo {
    height: 24px;
    margin-right: 0;
  }
  .header-main__logo img {
    height: 100%;
    width: auto;
    object-fit: contain;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
  }
  .header-main__burger, .header-main__btn-mobile {
    display: block;
  }
  .header-main__btn-mobile {
    margin-right: 0;
  }
  .header-main .header-main__form-search.form-search,
  .header-main .header-main__nav.nav {
    display: none;
  }
}

.header-menu {
  display: flex;
}
.header-menu__wrapper {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #DDE5EA;
  padding-top: 30px;
}
.header-menu__item {
  display: flex;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  margin-right: 50px;
  padding: 18px 0;
  border-bottom: 4px solid transparent;
  transition: border-bottom ease 0.3s, opacity ease 0.3s;
}
.header-menu__item .icon {
  margin: auto 0 auto 5px;
  color: #BBDFB3;
  font-size: 5px;
  transition: transform ease 0.6s;
}
.header-menu__item-img {
  margin-left: 9px;
}
.header-menu__item-body {
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  background: #fff;
  padding-bottom: 30px;
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.09);
  transform: translateY(42px);
  z-index: 999;
}
.header-menu__item-body-left {
  display: flex;
  grid-gap: 120px;
  width: 70%;
}
.header-menu__item:hover {
  color: #000;
  border-bottom: 4px solid #56AF40;
}
.header-menu__item:hover .icon {
  transform: rotate(180deg);
}
.header-menu__item:hover .header-menu__item-body {
  display: flex;
}
@media only screen and (max-width: 1220px) {
  .header-menu__item {
    margin-right: 30px;
  }
  .header-menu__item-body-left {
    grid-gap: 80px;
  }
}
@media only screen and (max-width: 1100px) {
  .header-menu {
    display: none;
  }
}

.list {
  list-style-type: none;
  padding: 0;
  margin: -8px 0;
  font-size: 14px;
  line-height: 130%;
}
.list__item {
  padding: 8px;
  transition: color ease 0.3s;
}
.list__item:hover {
  color: #56AF40;
}

.stock {
  display: flex;
  flex-direction: column;
}
.stock__title {
  font-family: "Gilroy-SemiBold";
  margin-top: 12px;
}

.header-mobile {
  display: none;
  position: fixed;
  top: 49px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  overflow-y: auto;
  opacity: 0;
  width: 100%;
  height: 100vh;
  padding-top: 35px;
  padding-bottom: 35px;
  border-top: 1px solid #BACAD5;
  background: #FFFFFF;
  pointer-events: none;
  transform: translateY(-49px);
  transition: transform ease 0.3s, opacity ease 0.3s;
  z-index: 9999;
}
.header-mobile__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-mobile.active {
  opacity: 1;
  pointer-events: initial;
  transform: translateY(0);
}
@media only screen and (max-width: 1100px) {
  .header-mobile {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .header-mobile {
    padding-top: 0;
    border-top: 1px solid #F2F2F2;
  }
  .header-mobile__row {
    display: none;
  }
}

.header-mobile-link {
  display: none;
}
@media only screen and (max-width: 767px) {
  .header-mobile-link {
    display: block;
    position: fixed;
    bottom: 0;
    background: #fff;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0px -1px 10px rgba(40, 37, 33, 0.03);
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.1));
    border-radius: 15px 15px 0px 0px;
  }
}

.header-search {
  display: none;
  position: absolute;
  width: 100%;
  background: #fff;
  padding: 10px;
  z-index: 111;
}
.header-search__form {
  display: flex;
  grid-gap: 10px;
  position: relative;
}
.header-search__close {
  position: absolute;
  right: 0;
  padding: 11px;
}
.header-search__close .icon {
  font-size: 12px;
  color: #000;
}

.header-info {
  position: relative;
  background: #BBDFB3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  gap: 20px;
}
.header-info::after, .header-info::before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 610px;
}
.header-info::after {
  right: 0;
  background: no-repeat url("/img/flower.png");
}
.header-info::before {
  left: 0;
  background: no-repeat url("/img/flower1.png");
}
.header-info__btn {
  width: max-content;
}
.header-info .subtitle {
  z-index: 1;
}
@media only screen and (max-width: 1280px) {
  .header-info::after, .header-info::before {
    width: 300px;
  }
}
@media only screen and (max-width: 767px) {
  .header-info .subtitle {
    font-size: 14px;
  }
}

.footer__top {
  border-top: 1px solid #DDE5EA;
}
.footer__bottom {
  font-size: 12px;
  line-height: 130%;
  border-top: 1px solid #DDE5EA;
}
.footer__container, .footer__row {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
.footer__row {
  align-items: baseline;
}
.footer__dev {
  display: flex;
  align-items: baseline;
  text-decoration: none;
  color: #000000;
}
.footer__dev:hover {
  color: #000000;
}
.footer__dev-label {
  margin-right: 15px;
}
@media only screen and (max-width: 1280px) {
  .footer__container {
    flex-direction: column;
  }
}
@media only screen and (max-width: 767px) {
  .footer__bottom .footer__row {
    padding: 20px 0 77px;
  }
}
@media only screen and (max-width: 500px) {
  .footer__row {
    flex-direction: column;
  }
  .footer__dev {
    width: 100%;
    justify-content: space-between;
    margin-top: 24px;
  }
}

.footer-navigation {
  display: flex;
  align-items: flex-start;
}
.footer-navigation__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-navigation__wrapper .icon {
  display: none;
  font-size: 7px;
  transition: transform ease 0.3s;
}
.footer-navigation__column {
  width: 250px;
}
.footer-navigation__list {
  max-width: 150px;
  margin: -9px 0;
}
.footer-navigation__link {
  display: flex;
}
@media only screen and (max-width: 1280px) {
  .footer-navigation {
    justify-content: space-between;
    margin-bottom: 45px;
  }
}
@media only screen and (max-width: 1024px) {
  .footer-navigation__column {
    width: 290px;
  }
}
@media only screen and (max-width: 768px) {
  .footer-navigation {
    flex-direction: column;
    margin-bottom: 40px;
  }
  .footer-navigation__column {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .footer-navigation__column:last-child {
    margin-bottom: 0;
  }
  .footer-navigation__list {
    display: none;
    max-width: 100%;
    margin-top: 0;
  }
  .footer-navigation__wrapper {
    cursor: pointer;
  }
  .footer-navigation__wrapper.active {
    padding-bottom: 15px;
  }
  .footer-navigation__wrapper.active .icon {
    transform: rotate(180deg);
  }
  .footer-navigation__wrapper .icon {
    display: block;
  }
  .footer-navigation__wrapper .footer-navigation__title {
    margin-bottom: 0;
  }
}

.footer-navigation__title,
.footer-contacts__title {
  color: #000000;
  display: flex;
  margin-bottom: 27px;
}
.footer-navigation__column li,
.footer-contacts__column li {
  font-size: 14px;
  line-height: 130%;
}
.footer-navigation__link,
.footer-contacts__link {
  padding: 9px 0;
}

.footer-contacts {
  display: grid;
  grid-template-rows: repeat(2, max-content);
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
.footer-contacts__link {
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-gap: 8px;
}
.footer-contacts__link span {
  grid-area: 1/2;
}
.footer-contacts__list {
  max-width: 200px;
  margin: -9px 0;
}
.footer-contacts__list .link {
  font-size: 12px;
  line-height: 20px;
}
.footer-contacts__list .icon {
  font-size: 16px;
  color: #BACAD5;
  margin-right: 9px;
}
.footer-contacts__logos {
  display: flex;
}
.footer-contacts__logo {
  margin-right: 25px;
}
.footer-contacts__logo:last-child {
  margin-right: 0;
}
.footer-contacts__logo-link:not(:last-child) {
  margin-right: 12px;
}
.footer-contacts__logo-link .icon {
  font-size: 32px;
  color: #BACAD5;
  transition: opacity ease 0.3s;
}
.footer-contacts__logo-link:hover .icon {
  opacity: 0.7;
}
@media only screen and (max-width: 1280px) {
  .footer-contacts {
    grid-template-columns: repeat(2, max-content);
    grid-gap: 40px;
  }
  .footer-contacts__column {
    width: 290px;
  }
}
@media only screen and (max-width: 768px) {
  .footer-contacts {
    grid-template-rows: repeat(4, max-content);
    grid-template-columns: 1fr;
  }
  .footer-contacts__column, .footer-contacts__list {
    width: 100%;
    max-width: 100%;
  }
}

.modals {
  display: none;
}

.mfp-close {
  display: none !important;
}

.mfp-bg {
  background: rgba(0, 0, 0, 0.3);
}

.modal {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 55px 76px 40px;
  background-color: #fff;
  color: #000000;
  position: relative;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.32);
  border-radius: 10px;
}
.modal__close {
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  box-shadow: none;
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  padding: 6px;
  align-items: center;
  justify-content: center;
}
.modal__close .icon {
  font-size: 12px;
  color: #000000;
}
.modal__title {
  text-align: center;
  font-family: "Gilroy-SemiBold";
  font-size: 20px;
  line-height: 24px;
}
.modal__description {
  margin: 0 auto;
}
.modal__content {
  margin-top: 45px;
}
.modal__btn {
  width: max-content;
  margin: auto;
  padding: 13px 24px;
}
.modal__body {
  z-index: 1;
}
.modal .form-default__item .input-group {
  margin-bottom: 20px;
}
.modal .form-default .input-group--help {
  height: 30px;
}
.modal .form-default__send {
  margin-bottom: 20px;
}
.modal .form-default__consent .input-group {
  display: flex;
  justify-content: center;
}
.modal--message {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 90px 30px;
  overflow: hidden;
}
.modal--message .modal__title {
  margin-bottom: 24px;
}
.modal--message .modal__description {
  max-width: 325px;
  text-align: center;
}
.modal--message .modal__description:last-child {
  margin-bottom: 0;
}
.modal--success {
  position: relative;
}
.modal--success .modal__title,
.modal--success .modal__description {
  margin-bottom: 12px;
}
.modal--success::before, .modal--success::after {
  content: "";
  position: absolute;
}
.modal--success::before {
  width: 177px;
  height: 56px;
  top: 6px;
  right: 67px;
  background: no-repeat url("/img/green-flowers.png");
}
.modal--success::after {
  width: 186px;
  height: 100px;
  bottom: 5px;
  left: 48px;
  background: no-repeat url("/img/green-flowers1.png");
}
.modal--error .modal__title {
  color: #EB5757;
}
.modal--city {
  max-width: 400px;
  padding: 30px 30px 5px;
}
.modal--city .modal__title {
  margin-bottom: 24px;
}
.modal--form {
  max-width: 290px;
  padding: 32px 16px;
}
.modal--form .form-default__consent {
  margin-bottom: 36px;
}
.modal--review {
  max-width: 532px;
  padding: 32px 16px;
}
.modal--review .modal__body {
  width: 100%;
}
.modal--review .form-default {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal--review .form-default__container .switch {
  margin-right: 11px;
}
.modal--review .form-default__row, .modal--review .form-default__item {
  width: 100%;
}

.result-list {
  height: 300px;
  margin: 0 -30px;
  padding: 0 30px;
}
.result-list__item {
  cursor: pointer;
  padding: 10px 0;
  transition: color ease 0.3s;
}
.result-list__item:hover {
  color: #56AF40;
}
.result-list__item--main {
  font-family: "Gilroy-Bold";
}
.result-list__item--select {
  font-family: "Gilroy-Bold";
  color: #56AF40;
}
.result-list .simplebar-scrollbar:before {
  background-color: #56AF40;
}
.result-list .simplebar-track {
  right: 8px;
}
.result-list .simplebar-track.simplebar-vertical {
  width: 4px;
}

.breadcrumb {
  position: relative;
  margin-top: 20px;
  margin-bottom: 15px;
  z-index: 1;
}
.breadcrumb__list {
  position: relative;
  width: 100%;
  display: inline-flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0;
  margin: 0;
  list-style-type: none;
  white-space: nowrap;
}
.breadcrumb__list::-webkit-scrollbar {
  display: none;
}
.breadcrumb__item {
  font-size: 12px;
  line-height: 18px;
  color: #BACAD5;
  margin-right: 8px;
}
.breadcrumb__item:last-child {
  margin-right: 0;
}
.breadcrumb a {
  color: inherit;
  text-decoration: none;
}
.breadcrumb a:hover {
  text-decoration: underline;
}

.pagination {
  display: flex;
  align-items: stretch;
  justify-content: center;
  min-width: 24px;
}
.pagination__control {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  transition: background ease 0.3s;
  margin: 0 4px;
  width: 27px;
  height: 27px;
  background: #EDF0F7;
  border-radius: 50%;
}
.pagination__control .icon {
  font-size: 12px;
}
.pagination__control:hover {
  background: #D3D9EA;
}
.pagination__list {
  margin: 0 10px;
  display: flex;
  align-items: center;
}
.pagination__link {
  margin: 0 4px;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: trasnsparent;
  color: #000000;
  text-decoration: none;
  font-family: "Gilroy-Medium";
  font-size: 15px;
  transition: background ease 0.3s;
  border-radius: 50%;
}
.pagination__link:hover:not(.pagination__link--not-hover) {
  background: #D3D9EA;
}
.pagination__link--current {
  background: #000000;
  color: #fff;
}

.wrap-preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrap-preloader .loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.section-preloader {
  position: relative;
}
.section-preloader__wrapper {
  position: absolute;
  top: 0;
  left: -1000px;
  right: -1000px;
  bottom: 0;
  background: transparent;
  z-index: -1;
  display: flex;
  z-index: 99999;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  transition: background ease 0.3s;
}
.section-preloader .loader {
  position: relative;
  transform: scale(0);
  transition: transform ease 0.3s;
}
.section-preloader--show .section-preloader__wrapper {
  pointer-events: auto;
  background: rgba(0, 0, 0, 0.3);
}
.section-preloader--show .loader {
  transform: scale(1);
}

.loader {
  font-size: 10px;
  width: 44px;
  height: 44px;
  border-radius: 100%;
}
.loader:before, .loader:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.loader:before {
  z-index: 100;
  animation: spin 1s infinite;
  border: 7px solid transparent;
  border-top-color: #56AF40;
  border-left-color: #56AF40;
}
.loader:after {
  border: 7px solid rgba(231, 241, 229, 0.3);
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.nav {
  display: flex;
  padding: 0;
  list-style-type: none;
  margin: 0 -8px;
}
.nav__item {
  padding: 0 8px;
}
.nav__item:last-child {
  padding-left: 22px;
}
.nav__item--link {
  display: flex;
  gap: 5px;
}
.nav__item--link .nav__link {
  color: #56AF40;
}
.nav__link {
  color: #000000;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 130%;
  text-decoration: none;
  transition: color ease 0.3s;
  position: relative;
}
.nav__link:hover {
  color: #56AF40;
}
.nav__link-counter {
  position: absolute;
  top: -10px;
  right: 13px;
  padding: 5px 10px;
  background-color: #56AF40;
  font-size: 12px;
  line-height: 12px;
  color: #fff;
  border-radius: 10px;
}
.nav__link-counter:empty {
  padding: 0;
}
.nav__link img {
  margin-right: 10px;
}
.nav__title {
  font-family: "Gilroy-SemiBold";
}
.nav--user-menu {
  margin: 0;
}
.nav--user-menu .nav__item {
  padding: 0;
  margin-left: 20px;
}
.nav--user-menu .nav__item:first-child {
  margin-left: 0;
}
.nav--user-menu .nav__item:last-child {
  margin-left: 20px;
}
.nav--user-menu .nav__link {
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 90px;
}
.nav--user-menu .nav__link span {
  font-family: "Gilroy-SemiBold";
  font-size: 12px;
  line-height: 11px;
}
.nav--user-menu .nav__link img {
  width: 20px;
  height: 20px;
  margin: 0 0 10px;
}
.nav--header {
  margin: 0;
  justify-content: space-between;
}
.nav--header .nav__item {
  width: 100%;
  min-width: 64px;
  padding: 0;
  transition: background ease 0.3s;
}
.nav--header .nav__item:first-child {
  border-radius: 15px 0px 0px 0px;
}
.nav--header .nav__item:last-child {
  border-radius: 0px 15px 0px 0px;
}
.nav--header .nav__item:hover {
  background: #56AF40;
}
.nav--header .nav__item:hover .nav__name,
.nav--header .nav__item:hover .icon {
  color: #FFFFFF;
}
.nav--header .nav__item:hover .icon-favorite,
.nav--header .nav__item:hover .icon-lk {
  stroke: #FFFFFF;
}
.nav--header .nav__link {
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px 0 8px;
}
.nav--header .nav__link .icon {
  font-size: 24px;
  margin-bottom: 2px;
  transition: color ease 0.3s;
}
.nav--header .nav__link .icon-favorite,
.nav--header .nav__link .icon-lk {
  stroke: #000000;
  fill: transparent;
}
.nav--header .nav__link img {
  width: 24px;
  height: 24px;
  margin: 0 0 2px;
}
.nav--header .nav__name {
  font-family: "Gilroy-SemiBold";
  font-size: 10px;
  line-height: 13px;
  transition: color ease 0.3s;
}

.custom-select {
  max-width: 100%;
  position: relative;
}
.custom-select--open .custom-select__arrow .icon {
  transform: rotate(180deg);
}
.custom-select--open .custom-select__body {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  transform: translateY(0px);
  opacity: 1;
  pointer-events: auto;
}
.custom-select__header {
  background: #F4F4F4;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  min-height: 40px;
  width: 100%;
  transition: background ease 0.3s, border ease 0.3s;
  border: 1px solid transparent;
  border-radius: 10px;
  font-size: 12px;
  line-height: 130%;
}
.custom-select__header:hover, .custom-select__header.active {
  border: 1px solid #56AF40;
}
.custom-select__body {
  position: absolute;
  top: 43px;
  left: 0;
  right: 0;
  background: #fff;
  transition: box-shadow ease 0.3s, transform ease 0.3s, opacity ease 0.3s;
  z-index: 10;
  max-height: 260px;
  transform: translateY(15px);
  border-radius: 10px;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
}
.custom-select__body-list {
  max-height: 260px;
}
.custom-select__body-list .simplebar-track {
  right: 5px;
}
.custom-select__selected {
  line-height: 1;
  font-size: 14px;
  color: #000000;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-select__arrow {
  color: #000000;
  margin-left: 27px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.custom-select .icon {
  color: rgba(0, 0, 0, 0.5);
  font-size: 8px !important;
  transition: transform ease 0.3s;
}
.custom-select__label {
  padding: 16px 28px 14px;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  background: #fff;
  transition: background ease 0.3s;
  cursor: pointer;
  display: block;
  border-radius: 10px;
  user-select: none;
}
.custom-select__label:hover {
  background: #E7F1E5;
}
.custom-select [type=checkbox],
.custom-select [type=radio] {
  display: none;
}
.custom-select [type=checkbox]:checked ~ .custom-select__label,
.custom-select [type=radio]:checked ~ .custom-select__label {
  background: #E7F1E5;
}

.form-search-wrapper {
  position: relative;
}

.form-search {
  display: flex;
  align-items: stretch;
  overflow: hidden;
  border-radius: 10px;
  background: #F4F4F4;
  width: 100%;
  max-width: 600px;
  border: 1px solid transparent;
  transition: border ease 0.3s;
}
.form-search__input {
  padding: 13px 20px;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: 12px;
  line-height: 14px;
  min-height: 40px;
}
.form-search__input:hover:not(:disabled), .form-search__input:focus:not(:disabled) {
  border: none;
}
.form-search__submit.btn {
  width: max-content;
  padding: 10px;
  border: none;
  background-color: transparent;
}
.form-search__submit.btn .icon {
  color: #56AF40;
  font-size: 20px;
}
.form-search__submit.btn:hover {
  background-color: transparent;
}
.form-search.active {
  border: 1px solid #56AF40;
}

.form-search-result {
  display: none;
  position: absolute;
  top: 32px;
  left: 0;
  padding: 10px;
  background: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  width: 350px;
}
.form-search-result__list {
  max-height: 255px;
}
.form-search-result__item {
  display: flex;
  text-decoration: none;
  color: #000000;
  margin-right: 6px;
}
.form-search-result__item-img {
  margin: 5px 12px 5px 0;
}
.form-search-result__item-title {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 12px;
  line-height: 14px;
  border-bottom: 1px solid #CDD8E2;
}
.form-search-result__item:last-child .form-search-result__item-title {
  border-bottom: none;
}

.input-rating {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  margin-bottom: 15px;
}
.input-rating input {
  display: none;
}
.input-rating__icon:not(:first-of-type) {
  padding-right: 7px;
}
.input-rating__icon:not(:last-of-type) {
  padding-left: 7px;
}
.input-rating__icon .icon {
  font-size: 21px;
  cursor: pointer;
  color: #BACAD5;
}
.input-rating__icon .icon-fill {
  display: none;
  color: #F2994A;
}
.input-rating__icon:hover .icon-empty {
  display: none;
}
.input-rating__icon:hover .icon-fill {
  display: inline-flex;
}
.input-rating__icon:hover ~ .input-rating__icon .icon-fill {
  display: inline-flex;
}
.input-rating__icon:hover ~ .input-rating__icon .icon-empty {
  display: none;
}
.input-rating__icon--active .icon-empty {
  display: none;
}
.input-rating__icon--active .icon-fill {
  display: inline-flex;
}
.input-rating__icon--active ~ .input-rating__icon .icon-empty {
  display: none;
}
.input-rating__icon--active ~ .input-rating__icon .icon-fill {
  display: inline-flex;
}

.accordeon {
  width: 100%;
  max-width: 530px;
  border-top: 1px solid #E2EAE9;
}
.accordeon:last-child {
  border-bottom: 1px solid #E2EAE9;
}
.accordeon .active .accordeon-head__btn::before {
  transform: rotate(90deg);
  opacity: 0;
}
.accordeon-head {
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  box-shadow: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 20px 16px;
  transition: background ease 0.3s, color ease 0.3s;
}
.accordeon-head__description {
  text-align: start;
}
.accordeon-head__description-count {
  display: inline-flex;
  justify-content: center;
  border-radius: 20px;
  margin-left: 8px;
  font-size: 13px;
  width: 20px;
  min-width: 20px;
  height: 20px;
  background: #56AF40;
  color: #FFFFFF;
}
.accordeon-head__value {
  font-family: "Gilroy-SemiBold";
  font-size: 14px;
  line-height: 20px;
  color: #8B928C;
  margin-right: 25px;
}
.accordeon-head .icon {
  width: 25px;
  font-size: 7px;
  transition: transform ease 0.3s;
}
.accordeon-head:hover {
  color: #56AF40;
}
.accordeon-head.active .icon {
  transform: rotate(180deg);
}
.accordeon-body {
  display: none;
  padding: 0 24px 0 20px;
}
.accordeon-body__item {
  padding: 16px 0 20px 20px;
  border-bottom: 1px solid #E2EAE9;
}
.accordeon-body__item:first-child {
  padding-top: 0;
}
.accordeon-body__item:last-child {
  border-bottom: none;
}
.accordeon-body__title {
  margin-bottom: 8px;
  position: relative;
}
.accordeon-body__title::before {
  content: "";
  position: absolute;
  top: 5px;
  left: -20px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #56AF40;
}
.accordeon-body__contact {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-gap: 8px;
  margin-bottom: 12px;
}
.accordeon-body__contact:last-child {
  margin-bottom: 0;
}
.accordeon-body__contact-name {
  color: #8B928C;
}
.accordeon-body__description {
  display: none;
  margin-bottom: 8px;
}
.accordeon--contacts {
  max-width: 640px;
  border-top: 1px solid #BACAD5;
}
.accordeon--contacts:last-child {
  border-bottom: 1px solid #BACAD5;
}
.accordeon--contacts .accordeon-head:hover, .accordeon--contacts .accordeon-head.active {
  color: #000000;
  background: #F3F8F2;
}
.accordeon--contacts .accordeon-body {
  background: #F3F8F2;
}
.accordeon--contacts .accordeon-body__title {
  font-family: "Gilroy-Bold";
}
.accordeon--filter .accordeon-head {
  padding: 20px 16px;
}
.accordeon--filter .accordeon-head .icon {
  width: 24px;
}
.accordeon--filter .accordeon-head:hover {
  color: #000000;
  background: #E7F1E5;
}
.accordeon--filter .accordeon-body {
  padding: 6px 16px 16px;
}
.accordeon--filter .accordeon-body .checkbox:not(:last-child) {
  margin-bottom: 20px;
}

.accordeon-wrapper {
  margin-bottom: 25px;
}

.item-dashed {
  width: max-content;
  color: #56AF40;
  border-bottom: 1px dashed #56AF40;
  cursor: pointer;
  transition: border-color ease 0.3s;
}
.item-dashed:hover {
  border-color: transparent;
}
.item-dashed.active {
  border-color: transparent;
}

.tooltip {
  opacity: 0;
  position: absolute;
  top: 20px;
  left: -20px;
  padding: 15px 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #000000;
  transition: opacity ease 0.3s;
  pointer-events: none;
  width: max-content;
  z-index: 10;
}
.tooltip__title {
  margin-bottom: 15px;
}
.tooltip__text {
  font-family: "Gilroy-SemiBold";
  font-size: 12px;
  line-height: 120%;
}
.tooltip__item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.tooltip__item:last-child {
  margin-bottom: 0;
}
.tooltip .icon {
  font-size: 13px;
  margin-right: 10px;
  color: #BACAD5;
}
.tooltip img {
  margin-right: 10px;
}
.tooltip--right {
  top: 13px;
  left: 28px;
}
@media only screen and (max-width: 500px) {
  .tooltip {
    min-width: 160px;
    width: 100%;
  }
}

.product-cards {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -10px 35px;
}
.product-cards .product-card {
  width: calc(25% - 20px);
  margin: 10px;
}
@media only screen and (max-width: 1320px) {
  .product-cards .product-card {
    width: calc(25% - 10px);
    margin: 5px;
  }
}
@media only screen and (max-width: 767px) {
  .product-cards .product-card {
    width: calc(50% - 8px);
    margin: 4px;
  }
}

.bx_catalog_item_container {
  height: 100%;
}

.product-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 310px;
}
.product-card__head {
  position: relative;
  z-index: 2;
}
.product-card__images {
  position: relative;
  display: block;
}
.product-card__images:hover .product-card__img {
  opacity: 0;
}
.product-card__images:hover .product-card__img--hover {
  opacity: 1;
}
.product-card__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
  transition: opacity ease 0.3s;
}
.product-card__img--hover {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.product-card__link {
  display: block;
}
.product-card__link:hover {
  color: #000000;
}
.product-card__link .product-card__body {
  height: auto;
}
.product-card__body {
  display: grid;
  grid-gap: 8px;
  height: 100%;
  line-height: 100%;
  margin-top: 8px;
}
.product-card__body--bottom {
  align-items: end;
  grid-template-columns: 1fr 1.15fr;
}
.product-card__body-right {
  align-self: flex-end;
}
.product-card__body-right .product-card__subtitle {
  color: #EB5757;
}
.product-card__title {
  display: flex;
}
.product-card__subtitle {
  color: #56AF40;
  font-family: "Gilroy-SemiBold";
  font-size: 12px;
  margin-bottom: 8px;
}
.product-card__price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 1240px) {
  .product-card__body--bottom {
    grid-template-columns: 1fr;
  }
  .product-card__price {
    justify-content: flex-start;
  }
  .product-card__price .price {
    margin-right: 16px;
  }
}
@media only screen and (max-width: 768px) {
  .product-card__title {
    max-width: 140px;
    word-break: break-all;
  }
  .product-card__price {
    justify-content: space-between;
  }
  .product-card__price .price {
    margin-right: 0;
  }
}

.marker {
  padding: 5px 4px;
  font-family: "Gilroy-Bold";
  background: #BACAD5;
  color: #FFFFFF;
  font-size: 10px;
  line-height: 100%;
  border-radius: 5px;
  position: absolute;
  top: 6px;
  left: 4px;
  cursor: default;
}
.marker--beige {
  background: #FFF1E1;
  color: #000000;
}

.price {
  display: flex;
  align-items: center;
}
.price__new {
  font-family: "Gilroy-Bold";
  font-size: 14px;
  line-height: 100%;
  margin-right: 8px;
}
.price__new:last-child {
  margin-right: 0;
}
.price__old {
  font-size: 10px;
  line-height: 100%;
  text-decoration-line: line-through;
}

.sale {
  padding: 4px 6px;
  background: #EB5757;
  font-size: 10px;
  line-height: 100%;
  color: #FFFFFF;
  border-radius: 10px;
}

.product-card-mini {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-gap: 16px;
  align-items: center;
  padding: 16px 0;
  border-top: 1px solid #EAEFF2;
  border-bottom: 1px solid #EAEFF2;
  width: max-content;
  margin: auto auto 20px;
}
.product-card-mini__img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  border-radius: 10px;
}
.product-card-mini__name {
  max-width: 210px;
  width: 100%;
}

.slider-pagination {
  display: flex;
  align-items: baseline;
}
.slider-pagination--intro {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  justify-content: center;
}
.slider-pagination--intro .swiper-pagination-bullet {
  background: rgba(255, 255, 255, 0.3);
}
.slider-pagination--intro .swiper-pagination-bullet-active {
  background: #fff;
}
.slider-pagination--black {
  bottom: 10px;
}
.slider-pagination--black .swiper-pagination-bullet {
  background: #000000;
}
.slider-pagination--little .swiper-pagination-bullet {
  width: 4px;
  height: 4px;
  margin: 0 2px !important;
}
.slider-pagination--little .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.slider-pagination--little .swiper-pagination-bullet-active {
  width: 12px;
}

.swiper-pagination-bullet {
  width: 7px;
  height: 7px;
  background: #BACAD5;
  opacity: 1;
  border-radius: 10px;
  margin-right: 13px;
  transition: width 0.3s, background 0.3s;
}
.swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.swiper-pagination-bullet-active {
  width: 21px;
}
@media only screen and (max-width: 767px) {
  .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
  }
  .swiper-pagination-bullet-active {
    width: 12px;
  }
}

.slider-intro .slider-pagination {
  bottom: 25px;
}
@media only screen and (max-width: 767px) {
  .slider-intro .slider-pagination {
    bottom: 8px;
  }
}

.swiper-slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.tabs {
  display: flex;
}
.tabs__item:not(:last-child) {
  margin-right: 15px;
}
.tabs__item:hover {
  color: inherit;
}
.tabs__item--active .checkbox-tabs__description {
  background: #BBDFB3;
}

.tabs-wrapper {
  overflow: auto;
}

.checkbox-tabs {
  cursor: pointer;
}
.checkbox-tabs input[type=checkbox] {
  display: none;
}
.checkbox-tabs input[type=checkbox]:checked ~ .checkbox-tabs__description {
  background: #BBDFB3;
}
.checkbox-tabs__description {
  display: block;
  width: max-content;
  padding: 8px 12px;
  background: #F4F4F4;
  border-radius: 10px;
  transition: background ease 0.3s;
}
.checkbox-tabs__description:hover {
  background: #E7F1E5;
}

.filter-shadow {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity ease 0.3s;
  pointer-events: none;
  z-index: 3;
}
.filter-shadow.active {
  pointer-events: auto;
  opacity: 1;
}

.filter {
  position: fixed;
  top: 0;
  right: 0;
  background: #fff;
  height: 100vh;
  width: 320px;
  overflow: auto;
  transform: translateX(1000px);
  transition: transform ease 0.3s;
  z-index: 1001;
}
.filter.active {
  transform: translateX(0);
}
.filter__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}
.filter__header .btn {
  padding: 0;
  line-height: 17px;
  width: max-content;
}
.filter__title {
  text-transform: uppercase;
}
.filter__btn {
  padding: 0 16px;
}
@media only screen and (max-width: 767px) {
  .filter {
    width: 100%;
  }
  .filter__title {
    position: relative;
    padding-left: 32px;
  }
  .filter__title::after, .filter__title::before {
    content: "";
    position: absolute;
    width: 24px;
    height: 1px;
    background: #000;
    top: 50%;
    left: 0;
  }
  .filter__title::after {
    transform: rotate(45deg) translateY(-50%);
  }
  .filter__title::before {
    transform: rotate(-45deg) translateY(-50%);
  }
  .filter .accordeon {
    max-width: 100%;
  }
}

.menu-toggle {
  display: none;
}
.menu-toggle:checked ~ .menu-btn__content {
  transform: rotate(45deg);
}
.menu-toggle:checked ~ .menu-btn__content::before {
  top: 0;
  transform: rotate(0);
}
.menu-toggle:checked ~ .menu-btn__content::after {
  top: 0;
  transform: rotate(90deg);
}
.menu-toggle:checked ~ .menu-btn__text {
  display: block;
}

.menu-btn {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 1;
}
.menu-btn__text {
  display: none;
  padding-left: 36px;
}
.menu-btn__content, .menu-btn__content::before, .menu-btn__content::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #000;
  transition-duration: 0.25s;
}
.menu-btn__content::before {
  content: "";
  top: -8px;
}
.menu-btn__content::after {
  content: "";
  top: 8px;
}

.choose-city {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 26px;
}
.choose-city__inner {
  display: flex;
  width: 100%;
  align-items: center;
}
.choose-city__selected {
  margin-left: 16px;
  font-family: "Gilroy-SemiBold";
  font-size: 14px;
}
.choose-city__btn {
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  box-shadow: none;
  font-family: "Gilroy-Medium";
  font-weight: normal;
  margin-left: auto;
  max-width: 110px;
  margin-left: 8px;
  border-radius: 10px;
  color: #FFFFFF;
  border-radius: 5px;
  background-color: #56AF40;
}
.choose-city__form {
  margin-top: 10px;
  width: 100%;
}

.delivery-banner {
  background-color: #F4F4F4;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 16px;
  padding-left: 20px;
}
.delivery-banner__head {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.delivery-banner__icon {
  width: 40px;
  height: 40px;
}
.delivery-banner__title {
  margin-left: 20px;
  font-family: "Gilroy-SemiBold";
  font-size: 16px;
  color: #000000;
}
.delivery-banner__text {
  font-size: 12px;
  color: #000000;
  font-family: "Gilroy-Medium";
}

.section-intro {
  margin-bottom: 45px;
}
.section-intro img {
  border-radius: 0;
}
@media only screen and (max-width: 767px) {
  .section-intro {
    margin: 0 -16px 30px;
  }
  .section-intro img {
    height: 180px;
  }
}

.page--home .grid-home {
  grid-row-gap: 25px;
  grid-column-gap: 80px;
}
@media only screen and (max-width: 1280px) {
  .page--home .grid-home {
    grid-column-gap: 60px;
  }
}
@media only screen and (max-width: 1024px) {
  .page--home .grid-home {
    grid-column-gap: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .page--home .grid-home {
    grid-column-gap: 20px;
  }
}

.section-home {
  margin-bottom: 45px;
}
.section-home:last-child {
  margin-bottom: 0;
}
.section-home__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.section-home__header .title {
  max-width: 250px;
  width: 100%;
}
.section-home__slide {
  max-width: 310px;
  height: initial;
}
.section-home__link {
  line-height: 100%;
  font-family: "Gilroy-Bold";
  color: #BACAD5;
}
.section-home__pagination {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.section-home__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.section-home__list-item {
  position: relative;
  padding-left: 36px;
  margin-bottom: 18px;
  counter-increment: item;
}
.section-home__list-item::before {
  content: counter(item);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  color: #FFFFFF;
  background: #56AF40;
  border-radius: 24px;
}
.section-home__products {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-bottom: 45px;
}
.section-home__container {
  grid-column: 2;
}
.section-home--row {
  display: grid;
  grid-template-columns: 290px 3fr;
  grid-gap: 45px;
}
.section-home--row .swiper-container {
  width: 100%;
}
.section-home--row .swiper-wrapper {
  height: auto;
}
.section-home--row .section-home__header {
  flex-direction: column;
  margin: 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.section-home--row .section-home__link {
  margin-top: 20px;
}
.section-home--row .section-home__pagination {
  grid-column: 2;
}
.section-home--border {
  padding-top: 45px;
  border-top: 1px solid #EAEFF2;
}
@media only screen and (max-width: 1320px) {
  .section-home--row {
    display: block;
  }
  .section-home--row .section-home__header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .section-home--row .section-home__header .title {
    max-width: 650px;
  }
  .section-home--row .section-home__text {
    width: 100%;
    max-width: 650px;
  }
}
@media only screen and (max-width: 1320px) and (max-width: 768px) {
  .section-home--row .section-home__header .title {
    max-width: 480px;
  }
  .section-home--row .section-home__text {
    max-width: 480px;
  }
}
@media only screen and (max-width: 1320px) {
  .section-home__products {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 768px) {
  .section-home {
    margin-bottom: 30px;
  }
  .section-home__header .title {
    width: max-content;
  }
}
@media only screen and (max-width: 767px) {
  .section-home__products {
    grid-template-columns: repeat(2, 1fr);
  }
}

.section-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}
.section-banner::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 250px;
  background-color: #FFF1E1;
  transform: translateY(-50%);
  z-index: -1;
}
.section-banner__img {
  margin-right: 90px;
}
.section-banner__img::before, .section-banner__img::after {
  content: "";
  position: absolute;
  height: 250px;
  top: 30px;
}
.section-banner__img::before {
  background: no-repeat url("/img/3.png");
  left: 0;
  width: 193px;
}
.section-banner__img::after {
  background: no-repeat url("/img/4.png");
  right: 0;
  width: 138px;
}
.section-banner__text {
  max-width: 430px;
  width: 100%;
}
.section-banner__title {
  font-family: "Gilroy-Bold";
  font-size: 20px;
  line-height: 18px;
  margin-bottom: 8px;
}
.section-banner__description {
  font-size: 14px;
  line-height: 18px;
  max-width: 290px;
  margin-bottom: 20px;
}
.section-banner__form {
  display: flex;
}
.section-banner__form .input {
  margin-right: 4px;
}
.section-banner__form .btn {
  width: max-content;
}
.section-banner__form .btn .icon {
  font-size: 11px;
}
@media only screen and (max-width: 1240px) {
  .section-banner::before {
    height: 200px;
  }
  .section-banner__img::before, .section-banner__img::after {
    content: none;
  }
}
@media only screen and (max-width: 1024px) {
  .section-banner {
    padding: 0 30px;
  }
  .section-banner__img {
    margin-right: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .section-banner {
    height: 200px;
    padding: 0 16px;
    margin: 0 -16px 30px;
  }
  .section-banner__img {
    display: none;
  }
}

.section-brands {
  background: #F4F4F4;
  padding: 25px 50px;
  margin-bottom: 45px;
}
.section-brands__wrapper {
  display: flex;
  justify-content: space-between;
}
.section-brands__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media only screen and (max-width: 768px) {
  .section-brands {
    overflow: auto;
    margin: 0 -16px 30px;
  }
  .section-brands::-webkit-scrollbar {
    display: none;
  }
  .section-brands__wrapper {
    width: max-content;
  }
}

.grid-home {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-bottom: 45px;
}
.grid-home:last-child {
  margin-bottom: 0;
}
.grid-home__img {
  margin-bottom: 12px;
}
.grid-home__title {
  margin-bottom: 10px;
}
.grid-home__title:last-child {
  margin-bottom: 0;
}
.grid-home--four {
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 35px;
}
.grid-home--contacts .grid-home__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 0;
}
.grid-home--contacts .card-wrapper {
  height: 100%;
}
.grid-home--club .grid-home__item {
  width: 100%;
  max-width: 290px;
}
@media only screen and (max-width: 1024px) {
  .grid-home--four {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 1023px) {
  .grid-home--club, .grid-home--contacts, .grid-home--tile, .grid-home--requisites {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-home--contacts .grid-home__item:last-child {
    grid-column: 1/3;
    max-height: 270px;
  }
  .grid-home--maps {
    grid-template-columns: 1fr;
  }
  .grid-home--maps .grid-home__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .grid-home--maps .grid-home__img {
    width: 100%;
    object-fit: contain;
  }
  .grid-home--requisites .grid-home__item:nth-child(2) {
    grid-column: 1;
  }
}
@media only screen and (max-width: 767px) {
  .grid-home {
    grid-template-columns: 1fr;
  }
  .grid-home--contacts .grid-home__item:last-child {
    grid-column: 1;
  }
  .grid-home--maps .grid-home__item {
    grid-template-columns: 1fr;
  }
}

.catalog-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -10px 35px;
}
@media only screen and (max-width: 1200px) {
  .catalog-wrapper {
    margin: -6px -6px 35px;
  }
}

.section-catalog {
  display: flex;
  padding: 25px 20px 11px;
  background: #F4F4F4;
  border-radius: 10px;
  margin: 10px;
  width: 100%;
  grid-gap: 30px;
  transition: background ease 0.3s;
}
.section-catalog__cover {
  display: block;
  margin-right: 18px;
}
.section-catalog__cover:last-child {
  margin-right: 0;
}
.section-catalog__img {
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
  margin-bottom: 11px;
  min-width: 270px;
}
.section-catalog__title {
  font-family: "Gilroy-SemiBold";
  font-size: 20px;
  line-height: 24px;
}
.section-catalog__list {
  display: grid;
  grid-template-columns: repeat(3, 300px);
  grid-row-gap: 1px;
  grid-column-gap: 30px;
  height: max-content;
}
.section-catalog__list-wrapper {
  width: 100%;
}
.section-catalog__item {
  display: flex;
  align-items: center;
  transition: background ease 0.3s, color ease 0.3s;
  padding: 0 12px;
}
.section-catalog__item span {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 12px 0;
  border-top: 1px solid #E3E7EB;
  transform: translateY(-1px);
}
.section-catalog__item:hover {
  background: #56AF40;
  color: #FFFFFF;
}
.section-catalog__btn {
  display: none;
}
.section-catalog--two-thirds {
  width: calc(75.0001875005% - 20px);
}
.section-catalog--two-thirds .section-catalog__list {
  grid-template-columns: repeat(2, 1fr);
}
.section-catalog--fourth {
  width: calc(25% - 20px);
}
.section-catalog--middle {
  width: calc(50% - 20px);
}
.section-catalog--middle .section-catalog__list {
  grid-template-columns: 1fr;
}
@media only screen and (max-width: 1300px) {
  .section-catalog__img {
    min-width: 250px;
  }
}
@media only screen and (max-width: 1200px) {
  .section-catalog {
    margin: 6px;
  }
  .section-catalog__img {
    min-width: 200px;
  }
  .section-catalog__list {
    display: grid;
    grid-template-columns: repeat(3, 220px);
  }
  .section-catalog__item {
    max-width: 220px;
  }
  .section-catalog--two-thirds {
    width: calc(75.0001875005% - 12px);
  }
  .section-catalog--fourth {
    width: calc(25% - 12px);
  }
  .section-catalog--middle {
    width: calc(50% - 12px);
  }
}
@media only screen and (max-width: 1023px) {
  .section-catalog {
    flex-direction: column;
  }
  .section-catalog__img {
    max-width: 80px;
    min-width: 100px;
    margin: 0 12px 0 0;
  }
  .section-catalog__cover {
    display: flex;
    align-items: center;
    margin: 0 0 12px 0;
  }
  .section-catalog__list {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
  }
  .section-catalog--two-thirds, .section-catalog--fourth, .section-catalog--middle {
    width: 100%;
  }
  .section-catalog--two-thirds .section-catalog__list, .section-catalog--fourth .section-catalog__list, .section-catalog--middle .section-catalog__list {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
  }
}
@media only screen and (max-width: 605px) {
  .section-catalog {
    padding: 12px;
    cursor: pointer;
    grid-gap: 15px;
  }
  .section-catalog__link {
    pointer-events: none;
  }
  .section-catalog__cover {
    margin: 0;
  }
  .section-catalog__img {
    min-width: 80px;
  }
  .section-catalog__list {
    margin: 0 -20px;
    grid-template-columns: 1fr;
  }
  .section-catalog__list-wrapper {
    display: none;
  }
  .section-catalog__item {
    max-width: 100%;
    padding: 0 20px;
  }
  .section-catalog__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    max-width: 24px;
    min-width: 24px;
    width: 100%;
    border-radius: 24px;
    background: #BACAD5;
    margin-left: auto;
    margin-right: 0;
    transition: background ease 0.3s;
  }
  .section-catalog__btn .icon {
    font-size: 6px;
    color: #FFFFFF;
    transition: transform ease 0.3s;
  }
  .section-catalog.active {
    background: #E7F1E5;
  }
  .section-catalog.active .section-catalog__btn {
    background: #56AF40;
  }
  .section-catalog.active .section-catalog__btn .icon {
    transform: rotate(180deg);
  }
  .section-catalog--two-thirds, .section-catalog--fourth, .section-catalog--middle {
    width: 100%;
  }
  .section-catalog--two-thirds .section-catalog__list, .section-catalog--fourth .section-catalog__list, .section-catalog--middle .section-catalog__list {
    grid-template-columns: 1fr;
  }
  .section-catalog--fourth .section-catalog__btn {
    display: none;
  }
  .section-catalog--fourth .section-catalog__link {
    pointer-events: all;
  }
}

.search-header {
  margin-bottom: 20px;
}

.page--catalog-list .back-btn {
  margin-top: 0;
}

.section-catalog-header {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 20px;
  margin-bottom: 20px;
}
.section-catalog-header .tabs {
  flex-wrap: wrap;
  margin: -4px;
}
.section-catalog-header .tabs__item {
  margin: 4px;
}

.section-footer {
  margin-top: 45px;
}
.section-footer__btn {
  width: max-content;
}
.section-footer__text {
  margin-bottom: 16px;
}

@media only screen and (max-width: 900px) {
  .page--product .accordeon {
    max-width: 100%;
  }
  .page--product .accordeon-head {
    padding: 20px 0;
  }
}

.section-product {
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
}
.section-product__wrapper {
  display: flex;
}
.section-product__card {
  margin-right: 20px;
}
.section-product__head {
  position: relative;
  z-index: 2;
}
.section-product__head .marker {
  top: 20px;
  left: 20px;
  padding: 7px 11px;
  color: #000000;
}
.section-product__head .like-btn {
  width: 28px;
  height: 28px;
  top: 20px;
  right: 20px;
}
.section-product__slider {
  min-width: 530px;
  max-width: 530px;
  width: 100%;
}
.section-product__slider .slider-pagination {
  bottom: 25px;
}
.section-product__info {
  width: 100%;
  max-width: 310px;
  padding-bottom: 25px;
  border-bottom: 1px solid #EAEFF2;
}
.section-product__column {
  margin-right: 130px;
}
.section-product__title {
  font-family: "Gilroy-SemiBold";
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;
}
.section-product__subtitle {
  margin-bottom: 8px;
}
.section-product__text-value {
  color: #AAAAAA;
  margin-left: 16px;
}
.section-product__price {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.section-product__price .price {
  margin-right: 20px;
}
.section-product__price .price__new {
  font-size: 20px;
}
.section-product__price .price__old {
  font-size: 14px;
}
.section-product__price .sale {
  font-size: 12px;
}
.section-product__btn {
  position: relative;
  margin-bottom: 25px;
}
.section-product__btn-count {
  font-family: "Gilroy-Bold";
  line-height: 100%;
  background: #FFFFFF;
  padding: 9px;
  color: #000000;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: absolute;
  top: -8px;
  right: 17px;
}
@media only screen and (max-width: 1280px) {
  .section-product__column {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1120px) {
  .section-product {
    flex-direction: column;
  }
  .section-product__column {
    margin-top: 45px;
  }
  .section-product__slider {
    max-width: 350px;
    min-width: 350px;
  }
  .section-product__slider .slider-pagination {
    bottom: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .section-product__info {
    max-width: 100%;
  }
  .section-product__wrapper {
    flex-direction: column;
  }
  .section-product__card {
    margin-right: 0;
  }
  .section-product .marker,
  .section-product .like-btn {
    top: 12px;
  }
  .section-product__slider {
    min-width: 100%;
  }
}

.section-reviews {
  width: 100%;
  max-width: 530px;
  margin-bottom: 65px;
}
.section-reviews__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.section-reviews__header .btn {
  width: max-content;
}
.section-reviews__cards {
  margin-bottom: 25px;
}
.section-reviews__title-value {
  font-size: 16px;
  line-height: 20px;
  margin-left: 8px;
}
@media only screen and (max-width: 900px) {
  .section-reviews {
    max-width: 100%;
  }
}

.review-card {
  background: #F4F4F4;
  padding: 20px 12px;
  border-radius: 10px;
  margin-bottom: 12px;
}
.review-card:last-child {
  margin-bottom: 0;
}
.review-card .btn {
  width: max-content;
}
.review-card__title {
  margin-bottom: 10px;
}
.review-card__title-text, .review-card__title-value {
  font-family: "Gilroy-Bold";
  font-size: 16px;
}
.review-card__title-value {
  margin-left: 8px;
}
.review-card__description {
  overflow: hidden;
  position: relative;
  max-height: 55px;
  margin-bottom: 10px;
  transition: max-height 0.3s ease-out;
}
.review-card__description::after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  max-height: 1.2em;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #F4F4F4 100%);
  pointer-events: none;
  transition: max-height 0.2s ease-out;
}
.review-card__description.active {
  max-height: 500px;
  transition: max-height 0.2s ease-in;
}
.review-card__description.active::after {
  max-height: 0;
  transition: max-height 0.2s ease-in;
}

.rating {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.rating__value {
  font-family: "Gilroy-Bold";
  font-size: 18px;
  margin-left: 15px;
}

.rating-total {
  background: #EBBE97;
  width: 160px;
  height: 24px;
}
.rating-total__background {
  background: #F2994A;
  position: relative;
  height: 24px;
}
.rating-total__background img {
  position: absolute;
}

.section-check {
  display: flex;
  align-items: center;
  margin-bottom: 34px;
}
.section-check .icon {
  font-size: 24px;
  color: #56AF40;
}
.section-check__description {
  font-family: "Gilroy-Bold";
  margin-left: 8px;
}
.section-check--error .icon {
  color: #EB5757;
}

.page--thank-you {
  margin-bottom: 20px;
}

.section-info {
  width: 100%;
  max-width: 288px;
  margin: auto;
}
.section-info p {
  text-align: center;
}
.section-info__title, .section-info__description {
  text-align: center;
}
.section-info__title {
  color: #56AF40;
  margin-bottom: 24px;
}
.section-info__description {
  font-family: "Gilroy-SemiBold";
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 36px;
}
.section-info__row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}
.section-info__row:last-child {
  margin-bottom: 0;
}
.section-info__tab {
  font-family: "Gilroy-Bold";
  font-size: 24px;
  line-height: 24px;
  margin-right: 32px;
}
.section-info__tab:hover {
  color: #BACAD5;
}
.section-info__tab:last-child {
  margin-right: 0;
}
.section-info__form {
  margin-bottom: 32px;
}
.section-info__footer {
  padding-top: 32px;
  border-top: 1px solid #EAEFF2;
}
.section-info__footer .btn {
  padding: 0;
  width: max-content;
  margin-right: 20px;
}
.section-info__footer .btn:last-child {
  margin-right: 0;
}
.section-info__footer .btn:hover {
  opacity: 0.7;
}
.section-info .checkbox {
  margin-bottom: 32px;
}
.section-info--flowers {
  padding: 160px 0;
  position: relative;
}
.section-info--flowers::after, .section-info--flowers::before {
  content: "";
  position: absolute;
}
.section-info--flowers::before {
  width: 177px;
  height: 56px;
  top: 45px;
  right: -8px;
  background: no-repeat url("/img/green-flowers.png");
}
.section-info--flowers::after {
  width: 186px;
  height: 100px;
  bottom: 25px;
  left: -24px;
  background: no-repeat url("/img/green-flowers1.png");
}
.section-info--error {
  padding-top: 40px;
}
.section-info--error::after, .section-info--error::before {
  content: none;
}
.section-info--error .section-info__title {
  font-family: "Gilroy-Bold";
  font-size: 100px;
  line-height: 75px;
  color: #56AF40;
}
.section-info--error .section-info__subtitle {
  text-align: center;
  font-family: "Gilroy-Bold";
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 16px;
}
.section-info--error .section-info__description {
  font-family: "Gilroy-Medium";
  font-size: 14px;
  line-height: 130%;
  margin-bottom: 24px;
}

.page--private-club .grid-home {
  grid-column-gap: 40px;
}
.page--private-club .section-home__header {
  display: block;
}
.page--private-club .section-home__title {
  width: 100%;
  margin-bottom: 16px;
}

.section-delivery {
  width: 100%;
  max-width: 640px;
  padding-bottom: 25px;
  border-bottom: 1px solid #EAEFF2;
  margin-bottom: 25px;
}
.section-delivery:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}
.section-delivery__title {
  color: #56AF40;
  margin-bottom: 20px;
}
.section-delivery__subtitle {
  color: #EB5757;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 16px;
}
.section-delivery__cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 18px;
}
.section-delivery__list-item {
  margin-bottom: 18px;
}
.section-delivery__list-item:last-child {
  margin-bottom: 0;
}
.section-delivery__text {
  margin-bottom: 8px;
}
.section-delivery__text-content {
  color: #5B5B5B;
  margin-right: 12px;
}
.section-delivery__text-content:last-child {
  margin-right: 0;
}
.section-delivery__text-value {
  color: #AAAAAA;
}
@media only screen and (max-width: 700px) {
  .section-delivery__cards {
    grid-template-columns: 1fr;
  }
}

.card-wrapper {
  background: #F4F4F4;
  border-radius: 10px;
  padding: 16px;
}
.card-wrapper__title {
  margin-bottom: 16px;
}
.card-wrapper__subtitle {
  margin-bottom: 20px;
}
.card-wrapper__container {
  width: 100%;
  max-width: 310px;
  padding: 20px 40px;
  margin: auto;
}
.card-wrapper__text {
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 12px;
  line-height: 18px;
  width: max-content;
}
.card-wrapper__text--light {
  background: #FFFFFF;
  color: #8B928C;
  margin-bottom: 16px;
}
.card-wrapper__text--green {
  background: #BBDFB3;
  font-family: "Gilroy-SemiBold";
}
.card-wrapper__text--strong {
  background: #FFFFFF;
  font-family: "Gilroy-SemiBold";
}
.card-wrapper__table {
  margin: 0 -6px;
}
.card-wrapper__table td {
  vertical-align: top;
  padding: 4px 6px;
}
.card-wrapper__table thead td {
  font-family: "Gilroy-Bold";
  font-size: 16px;
  line-height: 18px;
  color: #8B928C;
}
.card-wrapper__link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gilroy-Bold";
  font-size: 14px;
  line-height: 14px;
  margin: 0 auto 26px;
}
.card-wrapper__link .icon {
  font-size: 16px;
  color: #56AF40;
  margin-left: 6px;
}
.card-wrapper__btn {
  margin: 26px 0;
}
.card-wrapper__btn-save {
  padding: 11px 45px;
  text-align: left;
}
.card-wrapper__btn-save img {
  margin-right: 10px;
}
.card-wrapper__btn-login {
  padding: 0;
  align-items: center;
}
.card-wrapper__btn-login .button-save__icon .icon {
  color: #8B928C;
}
.card-wrapper__btn-login:hover .button-save__icon {
  background: #FFFFFF;
}
.card-wrapper__btn-login:hover .button-save__icon .icon {
  color: #EB5757;
}
.card-wrapper__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 70px;
}
.card-wrapper__phone {
  color: #FFFFFF;
  font-family: "Gilroy-SemiBold";
  font-size: 20px;
  line-height: 40px;
}
.card-wrapper__phone:hover {
  color: #FFFFFF;
}
.card-wrapper__header {
  display: flex;
  margin-bottom: 16px;
}
.card-wrapper__header .text-gray-second {
  margin-top: 12px;
  line-height: 14px;
}
.card-wrapper__img {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 12px;
}
.card-wrapper__form .input {
  background: #FFFFFF;
}
.card-wrapper__form .input-group {
  margin-bottom: 20px;
}
.card-wrapper__form .form-default__consent {
  margin-bottom: 36px;
}
.card-wrapper__form .btn {
  width: 100%;
}
.card-wrapper__form--hidden {
  display: none;
  padding-top: 12px;
  border-top: 1px solid #FFFFFF;
}
.card-wrapper--green {
  background: #56AF40;
  color: #FFFFFF;
}
.card-wrapper--green .card-wrapper__title {
  margin-bottom: 20px;
}
.card-wrapper--green .card-wrapper__value {
  font-family: "Gilroy-Bold";
  font-size: 50px;
  line-height: 100%;
}
.card-wrapper--green .card-wrapper__text {
  font-size: 16px;
  line-height: 18px;
  color: #BBDFB3;
  margin: auto;
}
.card-wrapper--form {
  padding: 0;
  height: max-content;
}
.card-wrapper--form .card-wrapper__title, .card-wrapper--form .card-wrapper__subtitle {
  text-align: center;
}
.card-wrapper--default {
  width: 100%;
  max-width: 310px;
}
.card-wrapper--order {
  width: 100%;
  max-width: 640px;
  margin-bottom: 20px;
}
.card-wrapper--order:last-child {
  margin-bottom: 0;
}
.card-wrapper--order .table {
  width: 100%;
  max-width: 250px;
}
@media only screen and (max-width: 670px) {
  .card-wrapper--order .products {
    display: none;
  }
  .card-wrapper--form .card-wrapper__container {
    padding: 20px 16px;
  }
}
@media only screen and (max-width: 630px) {
  .card-wrapper__content {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
  .card-wrapper__btn-save {
    max-width: 100%;
  }
}

.card-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.card-row:last-child {
  margin-bottom: 0;
}
.card-row .btn {
  width: max-content;
  padding: 0;
}
.card-row--top {
  align-items: flex-start;
}

.section-payment {
  width: 100%;
  max-width: 640px;
}
.section-payment__text {
  margin-bottom: 45px;
}
.section-payment__text a {
  text-decoration: underline;
}
.section-payment__list {
  margin-bottom: 30px;
}
.section-payment__logos {
  display: flex;
}
.section-payment__logo {
  margin-right: 25px;
}
.section-payment__logo:last-child {
  margin-right: 0;
}

.list-green {
  padding-left: 20px;
}
.list-green__item {
  width: 100%;
  max-width: 270px;
  margin-bottom: 8px;
  position: relative;
}
.list-green__item::before {
  content: "";
  position: absolute;
  top: 5px;
  left: -20px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #56AF40;
}

.stocks-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.stocks-container .stock__title {
  font-size: 20px;
  line-height: 24px;
}
@media only screen and (max-width: 1150px) {
  .stocks-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 900px) {
  .stocks-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 600px) {
  .stocks-container {
    grid-template-columns: 1fr;
  }
}

.btn-stock {
  width: max-content;
  padding: 13px 82px;
  margin: auto;
}

.section-front__img {
  width: 50%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
  margin-bottom: 45px;
}
.section-front__text {
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .section-front__img {
    width: 100%;
  }
}

.grid-container-wrapper {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 20px;
}
.grid-container-wrapper--lk {
  align-items: flex-end;
}
.grid-container-wrapper--lk .title {
  width: 100%;
  max-width: 280px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1340px) {
  .grid-container-wrapper {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 1023px) {
  .grid-content {
    width: 100%;
    max-width: 640px;
    margin: auto;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 310px 640px;
  grid-gap: 20px;
  margin-bottom: 25px;
}
.grid-container:last-child {
  margin-bottom: 0;
}
.grid-container .btn {
  height: max-content;
}
.grid-container__btn {
  max-width: 290px;
}
.grid-container__left {
  display: grid;
  grid-gap: 16px;
  height: max-content;
}
.grid-container__left .btn {
  font-family: "Gilroy-Medium";
  padding: 18px 30px;
  width: 100%;
  max-width: 255px;
}
.grid-container__description {
  grid-column: 2;
}
.grid-container__wrapper .card-wrapper:not(:last-child) {
  margin-bottom: 20px;
}
.grid-container__cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.grid-container__cards .card-wrapper:first-child {
  grid-column: 1/3;
}
.grid-container__search {
  max-width: 100%;
  margin-bottom: 30px;
}
.grid-container--border .grid-container__description {
  padding-top: 30px;
  border-top: 1px solid #DCE4EA;
}
@media only screen and (max-width: 1023px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
  .grid-container__description {
    grid-column: 1;
  }
  .grid-container__title {
    margin-bottom: 16px;
  }
  .grid-container__btn, .grid-container__left {
    margin-bottom: 32px;
  }
  .grid-container__left {
    grid-template-columns: repeat(2, max-content);
  }
}
@media only screen and (max-width: 630px) {
  .grid-container__cards {
    grid-template-columns: 1fr;
  }
  .grid-container__cards .card-wrapper:first-child {
    grid-column: 1;
  }
}
@media only screen and (max-width: 500px) {
  .grid-container__left {
    grid-template-columns: 1fr;
  }
}

.table {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 16px;
  margin-bottom: 16px;
}
.table:last-child {
  margin-bottom: 0;
}

.section-blog-main {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
  position: relative;
}
.section-blog-main:hover {
  color: inherit;
}
.section-blog-main__title, .section-blog-main__subtitle {
  width: 100%;
  max-width: 450px;
  text-align: center;
}
.section-blog-main__subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-blog-main__title {
  font-family: "Gilroy-SemiBold";
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 16px;
}
.section-blog-main__tag {
  font-size: 12px;
  line-height: 18px;
  padding: 3px 8px;
  background: #FFFFFF;
  border-radius: 10px;
}
.section-blog-main__text {
  font-family: "Gilroy-Bold";
  color: #FFFFFF;
  font-size: 12px;
  line-height: 100%;
  margin-left: 9px;
}
.section-blog-main__content {
  z-index: 1;
  margin-bottom: 45px;
}
.section-blog-main--front::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.26));
}
@media only screen and (max-width: 1024px) {
  .section-blog-main {
    height: 300px;
  }
}
@media only screen and (max-width: 540px) {
  .section-blog-main {
    height: 200px;
  }
}

.section-blog {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin: 45px 0;
}
@media only screen and (max-width: 1024px) {
  .section-blog {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 767px) {
  .section-blog {
    grid-template-columns: 1fr;
  }
}

.card-news {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  height: 100%;
}
.card-news__img {
  width: 100%;
  height: 190px !important;
  object-fit: cover;
  margin-bottom: 12px;
  border-radius: 10px;
}
.card-news__title, .card-news__description {
  line-height: 100%;
}
.card-news__description {
  font-family: "Gilroy-Bold";
  font-size: 14px;
  color: #BACAD5;
  margin-top: 12px;
}

.section-blog-subtitle {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.section-blog-subtitle__tag {
  font-size: 12px;
  line-height: 18px;
  padding: 3px 8px;
  background: #F4F4F4;
  color: #000000;
  width: max-content;
  border-radius: 10px;
}
.section-blog-subtitle__text {
  font-family: "Gilroy-Bold";
  color: #BACAD5;
  font-size: 12px;
  line-height: 100%;
}
@media only screen and (max-width: 540px) {
  .section-blog-subtitle {
    grid-template-columns: repeat(2, max-content);
    justify-content: flex-start;
  }
  .section-blog-subtitle__signature {
    grid-column: 1/3;
  }
}

.blog-container {
  width: 100%;
  max-width: 640px;
  margin: 0 auto 90px;
}

.title-block {
  margin-top: 40px;
}

.therm-box .word-box {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(5, 1fr);
}
.therm-box .word-box .item {
  float: none;
  width: 100%;
  padding-bottom: 0;
}
.therm-box .word-box .item a {
  text-decoration: underline;
  color: #56AF40;
}
.therm-box .word-box .item a:hover {
  text-decoration: none;
}
.therm-box .word-box .item .word.bold {
  font-family: "Gilroy-SemiBold";
}
@media only screen and (max-width: 1023px) {
  .therm-box .word-box {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 767px) {
  .therm-box .word-box {
    grid-template-columns: repeat(2, 1fr);
  }
}

.page--distributor .accordeon-body {
  display: block;
  width: 100%;
  max-width: 480px;
}
.page--distributor .card-wrapper {
  height: max-content;
}

.page--company .tabs-wrapper,
.page--shops .tabs-wrapper {
  margin-bottom: 30px;
}
.page--company .grid-container__cards,
.page--shops .grid-container__cards {
  margin-top: 45px;
}
@media only screen and (max-width: 640px) {
  .page--company .grid-container__cards,
  .page--shops .grid-container__cards {
    margin-top: 20px;
  }
}
.page--company .card-brand__title,
.page--shops .card-brand__title {
  min-width: 100%;
}

.page--shops .card-wrapper__title, .page--shops .card-wrapper__content {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 250px;
}
.page--shops .card-wrapper--green {
  text-align: center;
  padding: 28px;
  margin-top: 45px;
}
@media only screen and (max-width: 1023px) {
  .page--shops .grid-container__description {
    width: 100%;
    max-width: 640px;
  }
  .page--shops .grid-container__title {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 630px) {
  .page--shops .card-wrapper__content {
    grid-template-columns: repeat(2, 1fr);
  }
}

.page--vacancies .section-info {
  text-align: center;
}

.video-link {
  display: block;
  margin-bottom: 30px;
}
@media only screen and (max-width: 570px) {
  .video-link img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.section-company {
  display: flex;
  margin-bottom: 45px;
}
.section-company__content {
  margin-left: 130px;
}
.section-company__title, .section-company__subtitle {
  width: 100%;
  max-width: 360px;
}
.section-company__title {
  margin-bottom: 8px;
}
.section-company__subtitle {
  color: #8B928C;
}
@media only screen and (max-width: 1024px) {
  .section-company {
    width: 100%;
    max-width: 640px;
    flex-direction: column;
  }
  .section-company__content {
    margin: 45px 0 0;
  }
}

.card-brand {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  border: 1px solid #F4F4F4;
  border-radius: 10px;
  height: 100%;
  cursor: pointer;
  transition: border ease 0.3s;
}
.card-brand__img {
  max-height: 100px;
  object-fit: contain;
  margin-bottom: 16px;
}
.card-brand__title {
  width: 100%;
  min-width: 280px;
  text-align: center;
}
.card-brand__title .card-brand__title {
  color: #000000;
}
.card-brand--row {
  flex-direction: row;
  grid-gap: 12px;
}
.card-brand--row .card-brand__img {
  width: 220px;
  min-width: 220px;
  margin: 0;
}
.card-brand--row .card-brand__text {
  display: grid;
}
.card-brand--row .card-brand__text .card-brand__title {
  text-align: start;
  margin-bottom: 12px;
}
@media only screen and (max-width: 1350px) {
  .card-brand__title {
    min-width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .card-brand--row {
    flex-direction: column;
  }
}

.section-cities {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  margin-bottom: 30px;
}
.section-cities:last-child {
  margin-bottom: 0;
}
.section-cities a {
  padding: 8px 0;
}
@media only screen and (max-width: 425px) {
  .section-cities {
    grid-template-columns: repeat(2, 1fr);
  }
}

.page--brands .tabs-wrapper,
.page--private-label .tabs-wrapper {
  margin-bottom: 20px;
}

.page--private-label .grid-container__description {
  width: 100%;
  max-width: 640px;
}
.page--private-label .swiper-slide {
  width: 100% !important;
}

.section-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}
@media only screen and (max-width: 1024px) {
  .section-columns {
    grid-template-columns: 1fr;
  }
}

.section-column {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 12px;
}
@media only screen and (max-width: 1022px) {
  .section-column {
    grid-template-columns: repeat(2, max-content);
  }
}
@media only screen and (max-width: 680px) {
  .section-column {
    grid-template-columns: 1fr;
  }
  .section-column .button-save {
    max-width: 100%;
  }
}

.page--contacts .section-home__container {
  transform: translateX(-90px);
}
.page--contacts .card-wrapper {
  margin-bottom: 8px;
}
.page--contacts .card-wrapper:last-child {
  margin-bottom: 0;
}
.page--contacts .card-wrapper--green .card-wrapper__title {
  color: #BBDFB3;
}
@media only screen and (max-width: 1320px) {
  .page--contacts .section-home__container {
    transform: translateX(0);
  }
}

.page--lk .tabs-wrapper,
.page--lk-order .tabs-wrapper {
  margin-bottom: 30px;
}
.page--lk .section-check,
.page--lk-order .section-check {
  margin-bottom: 22px;
}
.page--lk .section-check__description,
.page--lk-order .section-check__description {
  color: #8B928C;
}
.page--lk .table,
.page--lk-order .table {
  width: 100%;
  max-width: 290px;
}
.page--lk .table .text-gray-second,
.page--lk-order .table .text-gray-second {
  width: 100%;
  max-width: 80px;
}

.page--lk-order .btn {
  width: 100%;
  max-width: 290px;
}

.section-lk {
  margin-bottom: 45px;
}

.products {
  display: flex;
}
.products__item, .products__item-last {
  width: 57px;
  height: 57px;
  border-radius: 10px;
}
.products__item {
  margin-right: 10px;
}
.products__item:last-child {
  margin-right: 0;
}
.products__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.products__item-last {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #BBDFB3;
}

.address-list {
  width: 100%;
  max-width: 450px;
}
.address-list__title {
  margin-bottom: 15px;
}
.address-list__item.btn:hover .icon {
  color: #56AF40;
}
.address-list__item {
  display: flex;
  justify-content: space-between;
  padding: 22px 0;
  border-bottom: 1px solid #EAEFF2;
}
.address-list__item:first-child {
  padding-top: 0;
}
.address-list__item--tr {
  border-bottom: none;
}
.address-list__wrapper {
  display: flex;
  align-items: center;
}
.address-list__text {
  font-size: 16px;
}
.address-list__icon {
  margin-right: 8px;
}
.address-list__btn {
  width: max-content;
}
.address-list .icon {
  font-size: 24px;
  color: #BBDFB3;
  transition: color ease 0.3s;
}
.address-list .form-default {
  width: 100%;
  max-width: 290px;
}
.address-list .form-default .input-group {
  margin-bottom: 16px;
}

.product-items {
  margin-bottom: 30px;
}

.product-item {
  display: flex;
  width: 100%;
  max-width: 450px;
  padding: 20px 0;
  border-bottom: 1px solid #EAEFF2;
}
.product-item__title {
  margin-bottom: 12px;
}
.product-item__img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 12px;
}
.product-item__info {
  width: 100%;
}
.product-item__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-item__row .btn {
  width: max-content;
}
.product-item__row .like-btn {
  position: relative;
  width: 24px;
  top: 0;
  left: 0;
  right: 0;
}
.product-item .price {
  margin-bottom: 16px;
}
.product-item .price__old {
  font-size: 14px;
}

.main-basket__title {
  margin-bottom: 16px;
  font-family: "Gilroy-Bold";
  font-size: 24px;
}
.main-basket *:before:not(.radio),
.main-basket *:after:not(.radio) {
  content: none !important;
}
.main-basket .bx-soa-pp-company,
.main-basket .order-step__promo-title,
.main-basket .order-location-notification,
.main-basket .bx-soa-coupon-input:after {
  display: none;
}
.main-basket #bx-soa-basket .bx-soa-section-title-container {
  display: none;
}
.main-basket #bx-soa-basket .bx-soa-section-content__delivery-banner {
  margin-bottom: 20px;
}
.main-basket .input-group__inner {
  display: flex;
}
.main-basket .input-group__inner .btn {
  max-width: 110px;
  margin-left: 8px;
  border-radius: 10px;
}
.main-basket .input--textarea {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  min-height: 90px;
}
.main-basket .bx-soa {
  gap: 130px;
  display: flex;
  align-items: flex-start;
}
@media only screen and (max-width: 1280px) {
  .main-basket .bx-soa {
    gap: 100px;
  }
}
@media only screen and (max-width: 1024px) {
  .main-basket .bx-soa {
    gap: 30px;
  }
}
@media only screen and (max-width: 475px) {
  .main-basket .bx-soa {
    flex-wrap: wrap;
    gap: 0;
  }
}
.main-basket .bx-soa #bx-soa-total-mobile,
.main-basket .bx-soa .bx-soa-cart-total-button-container.visible-xs {
  display: none;
}
.main-basket .bx-soa__column:nth-child(1) {
  width: 530px;
}
@media only screen and (max-width: 1024px) {
  .main-basket .bx-soa__column:nth-child(1) {
    width: 50%;
  }
}
@media only screen and (max-width: 475px) {
  .main-basket .bx-soa__column:nth-child(1) {
    width: 100%;
  }
}
.main-basket .bx-soa__column:nth-child(2) {
  width: calc(100% - 660px);
  position: sticky;
  top: 0;
  left: 0;
}
@media only screen and (max-width: 1024px) {
  .main-basket .bx-soa__column:nth-child(2) {
    width: 50%;
  }
}
@media only screen and (max-width: 475px) {
  .main-basket .bx-soa__column:nth-child(2) {
    width: 100%;
  }
}
.main-basket .bx-soa__column .product-card__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.main-basket .bx-soa__column .product-card__body-right {
  align-self: auto;
  width: 100%;
  margin-top: auto;
}
.main-basket .bx-soa-location-input-container {
  padding-top: 6px;
}
.main-basket .bx_soa_location .col-xs-12 {
  padding: 0;
}
.main-basket .bx_soa_location .dropdown-block {
  border: none;
  background: transparent;
  box-shadow: none;
}
.main-basket .bx_soa_location .dropdown-fade2white {
  display: none;
}
.main-basket .input-groups--order-person-type {
  display: none;
}
.main-basket .order-step__delivery-cards.active, .main-basket .row.bx-soa-more {
  display: none;
}
.main-basket .alert {
  display: none !important;
}
.main-basket .alert-warning {
  display: none !important;
}
.main-basket .input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}
.main-basket .input-group__wrapper {
  width: 100%;
  margin-bottom: 16px;
}
.main-basket .input-group__label {
  font-size: 14px;
  font-family: "Gilroy-SemiBold";
}
.main-basket .row {
  margin: 0;
}
.main-basket .bx-soa-section {
  border: none;
}
.main-basket .bx-soa-section.bx-step-completed {
  border: none;
}
.main-basket .bx-soa-section-title-container {
  background: transparent;
  padding: 0;
}
.main-basket .bx-soa-section-title-count {
  display: none;
}
.main-basket .bx-soa-section-title {
  padding-left: 0;
  color: #56AF40;
  font-size: 16px;
  font-family: "Gilroy-SemiBold";
  margin-bottom: 16px;
}
.main-basket .bx-soa-section-content {
  padding: 0;
}
.main-basket .bx-price-old {
  display: none;
}
.main-basket .bx-soa-cart-total {
  background-color: #E7F1E5;
  padding: 16px 18px;
}
.main-basket .bx-soa-cart-total-line {
  border-top: none;
  padding: 0;
  margin-bottom: 16px;
}
.main-basket .bx-soa-cart-total-line-highlighted {
  display: none;
}
.main-basket .bx-soa-cart-total-line-total {
  padding-top: 16px;
  border-top: 1px solid #BACAD5;
  margin-bottom: 0;
}
.main-basket .bx-soa-cart-total-line-total .bx-soa-cart-t {
  font-family: "Gilroy-Medium";
  font-size: 14px;
  font-weight: normal;
  color: #5B5B5B !important;
}
.main-basket .bx-soa-cart-total-line-total .bx-soa-cart-d {
  font-family: "Gilroy-Bold";
  font-size: 14px !important;
}
.main-basket .bx-soa-cart-t {
  font-family: "Gilroy-Medium";
  font-size: 12px;
  color: #8B928C;
  font-weight: normal !important;
}
.main-basket .bx-soa-cart-d {
  font-family: "Gilroy-Medium";
  font-size: 14px;
  color: #000000;
}
.main-basket .bx-soa-cart-d .bx-soa-price-free {
  color: inherit;
}
.main-basket .bx-soa-cart-d:first-letter {
  text-transform: capitalize;
}
.main-basket .visible-sm {
  display: none !important;
}
@media only screen and (max-width: 475px) {
  .main-basket .bx-soa-item-content {
    padding-top: 0;
    margin-left: 20px;
  }
}
.main-basket .bx-soa-item-title {
  font-size: 16px;
  font-family: "Gilroy-SemiBold";
  margin-bottom: 12px;
}
.main-basket .bx-soa-item-img-block {
  position: absolute;
  left: 0;
}
.main-basket .bx-soa-item-img-block a {
  display: flex;
  width: 60px;
  height: 60px;
}
@media only screen and (max-width: 475px) {
  .main-basket .bx-soa-item-img-block {
    position: relative;
    margin: 0;
  }
}
.main-basket .bx-soa-item-imgcontainer {
  border-radius: 10px;
  border: none;
  width: 60px;
  height: 60px;
}
.main-basket .bx-soa-item-tr {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(186, 202, 213, 0.3);
}
.main-basket .bx-soa-item-tr .bx-soa-item-td:nth-child(1) {
  order: 1;
}
.main-basket .bx-soa-item-tr .bx-soa-item-td:nth-child(3) {
  order: 2;
  margin-bottom: 12px;
}
.main-basket .bx-soa-item-tr .bx-soa-item-td:nth-child(2) {
  order: 3;
}
.main-basket .bx-soa-item-td {
  border: none;
  padding: 0;
  width: calc(100% - 72px) !important;
  margin-left: auto;
}
.main-basket .bx-soa-item-td-text {
  display: flex !important;
  align-items: center;
}
.main-basket .bx-soa-item-td-text .delete {
  position: absolute;
  right: 0;
}
.main-basket .bx-soa-item-td-text .delete span {
  position: relative;
}
.main-basket .bx-soa-item-td-text .delete span:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 15px;
  height: 2px;
  background-color: #000;
  transform: rotate(45deg);
}
.main-basket .bx-soa-item-td-text .delete span:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 15px;
  height: 2px;
  background-color: #000;
  transform: rotate(-45deg);
}
.main-basket .bx-soa-item-td-text .minus {
  background-color: #BACAD5;
  color: #FFFFFF;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
}
.main-basket .bx-soa-item-td-text .plus {
  background-color: #BACAD5;
  color: #FFFFFF;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
}
.main-basket .bx-soa-item-td-text input[type=text] {
  background: none;
  border: none;
  outline: none;
  width: 40px;
  padding: 0 12px;
  font-size: 16px;
  color: #000;
  text-align: center;
}
@media only screen and (max-width: 475px) {
  .main-basket .bx-soa-item-td {
    margin-left: 0;
  }
}
@media only screen and (max-width: 475px) {
  .main-basket .bx-soa-item-block {
    display: flex;
  }
}
@media only screen and (max-width: 475px) {
  .main-basket .bx-soa-item-properties {
    margin-left: 70px;
  }
}

.bx-soa-pp-company .radio__description {
  display: flex;
  flex-direction: column;
}
.bx-soa-pp-company .radio__description-type {
  font-family: "Gilroy-SemiBold";
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 8px;
}
.bx-soa-pp-company .radio__description-price {
  font-family: "Gilroy-SemiBold";
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 8px;
}
.bx-soa-pp-company .radio__description-time {
  font-family: "Gilroy-Medium";
  font-size: 12px;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.7);
}
.bx-soa-pp-company .radio__description-adress {
  font-family: "Gilroy-Medium";
  font-size: 12px;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.7);
}

.d-none {
  display: none !important;
}

.widget_list {
  padding-left: 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.ui {
  margin-bottom: 50px;
}
.ui-section {
  padding: 50px;
}
.ui-section td {
  padding: 10px;
}
.ui-section .input-group {
  max-width: 480px;
  margin-bottom: 20px;
}
.ui-header {
  padding: 35px 50px;
  background: #F4F7FB;
  color: #5A6872;
  font-size: 54px;
}
.ui-title {
  font-size: 24px;
  padding-bottom: 18px;
}

.ui-colors {
  display: flex;
  align-items: flex-start;
  margin-bottom: 45px;
  flex-wrap: wrap;
}

.ui-color {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 22px;
}
.ui-color__bg {
  width: 80px;
  height: 50px;
  margin-bottom: 10px;
  border: 1px solid #EFF2F5;
}
.ui-color__name {
  color: #5A6872;
  margin-bottom: 10px;
}
.ui-color__code {
  color: #000;
  text-decoration: underline;
}