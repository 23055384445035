.section-intro
	margin-bottom: 45px

	img
		border-radius: 0

	+max-screen(767px)
		margin: 0 -16px 30px

		img
			height: 180px


.page--home

	.grid-home
		grid-row-gap: 25px
		grid-column-gap: 80px

		+max-screen(1280px)
			grid-column-gap: 60px

		+max-screen(1024px)
			grid-column-gap: 25px

		+max-screen(768px)
			grid-column-gap: 20px




.section-home
	margin-bottom: 45px

	&:last-child
		margin-bottom: 0

	&__header
		display: flex
		justify-content: space-between
		align-items: center
		margin-bottom: 20px

		.title
			max-width: 250px
			width: 100%

	&__slide
		max-width: 310px
		height: initial

	&__link
		line-height: 100%
		font-family: $ffb
		color: $gray

	&__pagination
		display: flex
		justify-content: center
		margin-top: 25px

	&__list
		margin: 0
		padding: 0
		list-style-type: none

		&-item
			position: relative
			padding-left: 36px
			margin-bottom: 18px
			counter-increment: item

			&::before
				content: counter(item)
				position: absolute
				display: flex
				justify-content: center
				align-items: center
				width: 24px
				height: 24px
				top: 0
				left: 0
				color: $white
				background: $green
				border-radius: 24px

	&__products
		display: grid
		grid-template-columns: repeat(3, 1fr)
		grid-gap: 20px
		margin-bottom: 45px

	&__container
		grid-column: 2

	&--row
		display: grid
		grid-template-columns: 290px 3fr
		grid-gap: 45px

		.swiper-container
			width: 100%

		.swiper-wrapper
			height: auto

		.section-home

			&__header
				flex-direction: column
				margin: 0
				align-items: flex-start
				justify-content: flex-start

			&__link
				margin-top: 20px

			&__pagination
				grid-column: 2

	&--border
		padding-top: 45px
		border-top: 1px solid #EAEFF2

	+max-screen(1320px)

		&--row
			display: block

			.section-home

				&__header
					flex-direction: row
					align-items: center
					justify-content: space-between
					margin-bottom: 20px

					.title
						max-width: 650px

				&__text
					width: 100%
					max-width: 650px

				+max-screen(768px)

					&__header

						.title
							max-width: 480px

					&__text
						max-width: 480px

		&__products
			grid-template-columns: repeat(4, 1fr)

	+max-screen(768px)
		margin-bottom: 30px

		&__header

			.title
				width: max-content

	+max-screen(767px)

		&__products
			grid-template-columns: repeat(2, 1fr)

	// +max-screen(500px)

	// 	&__products
	// 		grid-template-columns: 1fr



.section-banner
	display: flex
	justify-content: center
	align-items: center
	position: relative
	margin-bottom: 20px

	&::before
		content: ""
		position: absolute
		top: 50%
		left: 0
		width: 100%
		height: 250px
		background-color: $color-bg-3
		transform: translateY(-50%)
		z-index: -1

	&__img
		margin-right: 90px

		&::before,
		&::after
			content: ""
			position: absolute
			height: 250px
			top: 30px

		&::before
			background: no-repeat url('/img/3.png')
			left: 0
			width: 193px

		&::after
			background: no-repeat url('/img/4.png')
			right: 0
			width: 138px

	&__text
		max-width: 430px
		width: 100%

	&__title
		font-family: $ffb
		font-size: 20px
		line-height: 18px
		margin-bottom: 8px

	&__description
		font-size: 14px
		line-height: 18px
		max-width: 290px
		margin-bottom: 20px

	&__form
		display: flex

		.input
			margin-right: 4px

		.btn
			width: max-content

			.icon
				font-size: 11px

	+max-screen(1240px)

		&::before
			height: 200px

		&__img

			&::before,
			&::after
				content: none

	+max-screen(1024px)
		padding: 0 30px

		&__img
			margin-right: 40px

	+max-screen(767px)
		height: 200px
		padding: 0 16px
		margin: 0 -16px 30px

		&__img
			display: none



.section-brands
	background: $color-bg-1
	padding: 25px 50px
	margin-bottom: 45px

	&__wrapper
		display: flex
		justify-content: space-between

	&__logo

		img
			width: 100%
			height: 100%
			object-fit: contain

	+max-screen(768px)
		overflow: auto
		margin: 0 -16px 30px

		&::-webkit-scrollbar
			display: none

		&__wrapper
			width: max-content


.grid-home
	display: grid
	grid-template-columns: repeat(3, 1fr)
	grid-gap: 20px
	margin-bottom: 45px

	&:last-child
		margin-bottom: 0

	&__img
		margin-bottom: 12px

	&__title
		margin-bottom: 10px

		&:last-child
			margin-bottom: 0

	&--four
		grid-template-columns: repeat(4, 1fr)
		grid-gap: 35px

	&--contacts

		.grid-home
			&__img
				width: 100%
				height: 100%
				object-fit: cover
				border-radius: 10px
				margin-bottom: 0

		.card-wrapper
			height: 100%

	&--club

		.grid-home__item
			width: 100%
			max-width: 290px

	+max-screen(1024px)

		&--four
			grid-template-columns: repeat(2, 1fr)

	+max-screen(1023px)

		&--club,
		&--contacts,
		&--tile,
		&--requisites
			grid-template-columns: repeat(2, 1fr)

		&--contacts

			.grid-home__item
				&:last-child
					grid-column: 1/3
					max-height: 270px

		&--maps
			grid-template-columns: 1fr

			.grid-home
				&__item
					display: grid
					grid-template-columns: 1fr 1fr
					grid-gap: 10px

				&__img
					width: 100%
					object-fit: contain

		&--requisites

			.grid-home__item
				&:nth-child(2)
					grid-column: 1


	+max-screen(767px)
		grid-template-columns: 1fr

		&--contacts

			.grid-home__item
				&:last-child
					grid-column: 1

		&--maps

			.grid-home
				&__item
					grid-template-columns: 1fr
